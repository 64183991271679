import { useLocation, useNavigate } from 'react-router-dom';
import DarkTooltip from '../../components/common/DarkToolTip';

const LeftMenuItem = ({ menuItem, isCollapsed }) => {
  const nav = useNavigate();
  const location = useLocation();
  const { icon, name, url, mui, redirect } = menuItem;

  const ifActive = location.pathname === url;

  const handleNavigate = () => (redirect ? window.open(url, '_blank') : nav(
    url));

  const button = (
    <button
      onClick={handleNavigate}
      className={ifActive ? 'active' : ''}
      style={{
        whiteSpace: 'nowrap',
        width: isCollapsed ? '52px' : '100%',
        margin: isCollapsed ? 'auto' : '0'
      }}>
      {mui ? icon : <img src={icon} alt='' />}

      {!isCollapsed && name}
    </button>
  );

  return (
    <li>
      {isCollapsed ? (
        <DarkTooltip
          placement='right'
          title={name}
          styles={{
            right: '-10px',
            padding: '10px',
            marginLeft: '5px!important'
          }}
        >
          {button}
        </DarkTooltip>
      ) : button
      }

    </li>
  );
};

export default LeftMenuItem;
