import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  leaderboardList: [],
  leaderboardData: {}
};

export const { actions, reducer } = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    setLeaderboardList: (state, action) => {
      state.leaderboardList = action.payload;
    },
    setLeaderboardData: (state, action) => {
      state.leaderboardData = action.payload;
    },
    resetLeaderboardData: (state) => {
      state.leaderboardList = [];
      state.leaderboardData = {};
    }
  }
});

export default reducer;
