import axios from 'axios';

const UNAUTHORIZED = 401;
const SERVICEUNAVAILABLE = 503;

window.addEventListener('message', (event) => {
  if (event.data.type !== 'AUTH_TOKEN') return;

  const token = event.data.token;

  const currentToken = localStorage.getItem('token');

  // when the user already has an old token - it needs to be deleted
  if (currentToken && token && currentToken !== token) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.reload();
  }

  if (!localStorage.getItem('token')) {
    if (token) {
      localStorage.setItem('token', token);
    }

    window.location.reload();
  }

  if (token) {
    event.source.postMessage({
      type: 'AUTH_COMPLETE'
    }, '*');
  }
});

export const setInstanceAuth = (instance) => {
  instance.interceptors.request.use(function (config) {
    config.headers = config.headers ?? {};

    let token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    let impersonateUserToken = localStorage.getItem('impersonateUserToken');
    config.headers.Impersonated = Boolean(impersonateUserToken);

    return config;
  });
};

const config = {
  baseURL: process.env.REACT_APP_API_HOST
};
const instance = axios.create(config);

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Logout
    if (error.response?.status === UNAUTHORIZED && window.location.pathname !== '/login') {
      localStorage.removeItem('token');
      window.Intercom('shutdown');
      window.location.pathname = '/login'
    }

    if (error.response?.status === SERVICEUNAVAILABLE) {
      if (window.location.pathname !== '/503') {
        localStorage.setItem('prevPage', window.location.pathname);
        window.location.pathname = '/503';
      }

      return;
    }

    return Promise.reject(error);
  }
);
setInstanceAuth(instance);

export default instance;
