import { useParams } from "react-router-dom";
import { useState } from "react";
import { NumericFormat } from "react-number-format";
import { useDispatch } from "react-redux";
import { formatMoney } from "../../utils";
import { useDebouncedEffect } from "../../hooks/useDebouncedEffect";
import axios from "../../axios";
import { actions } from "../../store/expenses/expenses.reducers";
import toastService from "../../services/toastService";

const ExpensesPreviewTableRow = ({ itemData }) => {
  const dispatch = useDispatch();
  const { payrollId } = useParams();

  const {
    repair_maint_expense,
    other_expense,
    fixed_expenses,
    damages_expense,
    tickets_expense,
    tolls_expense,
    station_name,
    company_name
  } = itemData;

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [tollsExpense, setTollsExpense] = useState(+tolls_expense ? tolls_expense : "0.00");
  const [ticketsExpense, setTicketsExpense] = useState(+tickets_expense ? tickets_expense : "0.00");
  const [damagesExpense, setDamagesExpense] = useState(+damages_expense ? damages_expense : "0.00");
  const [repairMaintExpense, setRepairMaintExpense] = useState(
    +repair_maint_expense ? repair_maint_expense : "0.00"
  );
  const [otherExpense, setOtherExpense] = useState(+other_expense ? other_expense : "0.00");

  useDebouncedEffect(
    () => {
      if (isFirstRender) {
        setIsFirstRender(false);
        return;
      }

      const body = {
        tolls_expense: tollsExpense,
        tickets_expense: ticketsExpense,
        damages_expense: damagesExpense,
        repair_maint_expense: repairMaintExpense,
        other_expense: otherExpense
      };

      axios
        .put(`payroll/${payrollId}/expenses/${itemData.id}`, body)
        .catch(() =>
          toastService.error('Something went wrong')
        );

      dispatch(
        actions.changeExpensesData({
          id: itemData.id,
          tollsExpense: tollsExpense,
          ticketsExpense: ticketsExpense,
          damagesExpense: damagesExpense,
          repairMaintExpense: repairMaintExpense,
          otherExpense: otherExpense
        })
      );
    },
    [tollsExpense, ticketsExpense, damagesExpense, repairMaintExpense, otherExpense],
    500
  );

  const handleTollsExpenseChange = (v) =>
    setTollsExpense(!v || v !== "0.00" ? v?.toFixed(2) : "0.00");

  const handleTicketsExpenseChange = (v) =>
    setTicketsExpense(!v || v !== "0.00" ? v?.toFixed(2) : "0.00");

  const handleDamagesExpenseChange = (v) =>
    setDamagesExpense(!v || v !== "0.00" ? v?.toFixed(2) : "0.00");

  const handleRepairMaintExpenseChange = (v) =>
    setRepairMaintExpense(!v || v !== "0.00" ? v?.toFixed(2) : "0.00");

  const handleOtherExpenseChange = (v) =>
    setOtherExpense(!v || v !== "0.00" ? v?.toFixed(2) : "0.00");

  return (
    <tr>
      <td style={{ maxWidth: 85 }}>{company_name}</td>
      <td>{station_name}</td>
      <td>
        <div className="form-item statement-input fuel">
          <div className="input-box fuel">
            <NumericFormat
              value={tollsExpense}
              onValueChange={(values) => {
                handleTollsExpenseChange(values.floatValue || 0);
              }}
              allowEmptyFormatting
              prefix="$"
              thousandSeparator
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue < 100000 || [0, null, undefined].includes(floatValue);
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div className="form-item statement-input fuel">
          <div className="input-box fuel">
            <NumericFormat
              value={ticketsExpense}
              onValueChange={(values) => {
                handleTicketsExpenseChange(values.floatValue || 0);
              }}
              allowEmptyFormatting
              prefix="$"
              thousandSeparator
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue < 100000 || [0, null, undefined].includes(floatValue);
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div className="form-item statement-input fuel">
          <div className="input-box fuel">
            <NumericFormat
              value={damagesExpense}
              onValueChange={(values) => {
                handleDamagesExpenseChange(values.floatValue || 0);
              }}
              allowEmptyFormatting
              prefix="$"
              thousandSeparator
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue < 100000 || [0, null, undefined].includes(floatValue);
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div className="form-item statement-input fuel">
          <div className="input-box fuel">
            <NumericFormat
              value={repairMaintExpense}
              onValueChange={(values) => {
                handleRepairMaintExpenseChange(values.floatValue || 0);
              }}
              allowEmptyFormatting
              prefix="$"
              thousandSeparator
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue < 100000 || [0, null, undefined].includes(floatValue);
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div className="form-item statement-input fuel">
          <div className="input-box fuel">
            <NumericFormat
              value={otherExpense}
              onValueChange={(values) => {
                handleOtherExpenseChange(values.floatValue || 0);
              }}
              allowEmptyFormatting
              prefix="$"
              thousandSeparator
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue < 100000 || [0, null, undefined].includes(floatValue);
              }}
            />
          </div>
        </div>
      </td>
      <td>{formatMoney(fixed_expenses, true)}</td>
    </tr>
  );
};

export default ExpensesPreviewTableRow;
