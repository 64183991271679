import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardImg from "../assets/images/dashboard-img.png";
import GoogleWhiteBig from "../assets/images/login/google-white-big.png";
import PaychexBig from "../assets/images/login/paychex-big.png";
import FedExLogoBig from "../assets/images/login/FedEx-Logo-big.png";
import StripeBig from "../assets/images/login/Stripe-big.png";
import LoginLogo from "../components/common/LoginLogo";
import Loading from "../components/common/Loading";
import axios from "../axios";
import toastService from "../services/toastService";

const EmailVerify = () => {
  const { search } = useLocation();
  const nav = useNavigate();

  const [loading, setLoading] = useState(false);

  const useQuery = () => {
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();

  useEffect(() => {
    window.Intercom("boot", {
      app_id: "dyklmyeq"
    });

    setLoading(true);

    axios
      .post(`/auth/email/verify/${query.get("id")}/${query.get("hash")}`)
      .then((res) => {
        if (res.errors) {
          toastService.error(res.message)
          nav("/login");
          return;
        }
        toastService.success(res.data.message)
        nav("/login");
        return;
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      )
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <LoginLogo />

      <div className="login-main-sec">
        <div className="login-left-sec">
          <div className="inner-sec">
            <h1>Verification page</h1>
            <br></br>
            {loading ? (
              <>
                <p>Verification in progress</p>
                <br></br>
                <Loading />
              </>
            ) : (
              <p>Verification success</p>
            )}
          </div>
        </div>

        <div className="login-right-sec">
          <div className="inner-sec">
            <h2>The simplest way to do payroll for FedEx contractor</h2>
            <p>Enter your credentials to access your account</p>
            <img src={DashboardImg} alt="" />
            <div className="login-marquee">
              <div className="track">
                <img src={GoogleWhiteBig} alt="" />
                <img src={PaychexBig} alt="" />
                <img src={FedExLogoBig} alt="" />
                <img src={StripeBig} alt="" />
                <img src={GoogleWhiteBig} alt="" />
                <img src={PaychexBig} alt="" />
                <img src={FedExLogoBig} alt="" />
                <img src={StripeBig} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-sec">
          <p>2024 Fleet Wage Inc, All right Reserved</p>
        </div>
      </div>
    </>
  );
};

export default EmailVerify;
