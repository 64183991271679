import { useNavigate } from "react-router-dom";
import * as dayjs from "dayjs";
import FolderIcon from "../../assets/images/run-payroll/folder.png";
import { formatMoney } from "../../utils";
import { getPayrollPeriodString } from '../../helpers/helpers';

const ExpensesTableRow = ({ itemData }) => {
  const nav = useNavigate();

  const { date, statements_count, created_at, total_expenses, updated_at } = itemData;

  const handlePreviewClick = () => nav(`/expenses-preview/${itemData.id}`);

  return (
    <tr>
      <td onClick={handlePreviewClick} className="pointer">
        <img src={FolderIcon} alt="" />
        <strong>
          {getPayrollPeriodString(date)}
        </strong>
        <span>{statements_count} Statements</span>
      </td>
      <td onClick={handlePreviewClick} className="pointer">
        {dayjs(created_at).format("MMM DD, YYYY hh:mm a")}
      </td>
      <td onClick={handlePreviewClick} className="pointer">
        {dayjs(updated_at).format("MMM DD, YYYY hh:mm a")}
      </td>
      <td onClick={handlePreviewClick} className="pointer text-align-right">
        {formatMoney(total_expenses)}
      </td>
    </tr>
  );
};

export default ExpensesTableRow;
