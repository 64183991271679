import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CloseCircle from "../../assets/images/close-circle.png";
import EmployeeForm from "../add-employee/EmployeeForm";
import axios from "../../axios";
import { actions } from "../../store/data/data.reducers";
import toastService from "../../services/toastService";

const AddMissingEmployeeModal = ({ open, handleClose, needAddEmployeeList }) => {
  const dispatch = useDispatch();
  const [isAddComplete, setIsAddComplete] = useState(false);
  const [employeeCount, setEmployeeCount] = useState(0);

  const isLastAddMissingEmployee = needAddEmployeeList.length - employeeCount === 1;

  const handleCurClose = () => {
    handleClose();
    setIsAddComplete(false);
    setEmployeeCount(0);
  };

  const fetchCompanies = () => {
    axios
      .get("/company-list")
      .then((response) => {
        dispatch(actions.setCompanies(response.data.companies));
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      );
  };

  const fetchStations = () => {
    axios
      .get("/station-list")
      .then((response) => {
        dispatch(actions.setStations(response.data.stations));
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      );
  };

  const fetchPositions = () => {
    axios
      .get("/settings/positions")
      .then((response) => {
        dispatch(actions.setPositions(response.data.positions));
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      );
  };

  useEffect(() => {
    fetchCompanies();
    fetchStations();
    fetchPositions();
  }, []);

  useEffect(() => {
    if (isAddComplete && !isLastAddMissingEmployee) {
      setIsAddComplete(false);
      setEmployeeCount(employeeCount + 1);
    }
    console.log(isAddComplete, isLastAddMissingEmployee);
    if (isAddComplete && isLastAddMissingEmployee) {
      toastService.success("Employees Successfully Added! You Can Proceed Now")
      handleCurClose();
    }
  }, [isAddComplete]);

  return (
    <div
      className="employees-popup employees-edit-popup"
      style={{ display: open ? "flex" : "none" }}>
      <div className="employees-inner upload-gc-inner" style={{ overflow: "hidden" }}>
        <div className="popup-top-bar upload-gc-top-bar-container add-missing-employee-header">
          <div className="upload-gc-top-bar add-missing-employee-title">
            <div>Add New Employee</div>
          </div>
          <button className="popup-close edit-popup-cancel" onClick={handleCurClose}>
            <img src={CloseCircle} alt="" />
          </button>
        </div>

        <div className="add-missing-employee-header-subtitle">
          You need to fill in the employee details. Complete the required fields and click “Save and
          Next” to proceed to the next employee
        </div>

        <div className="add-missing-employee-profile">
          <div className="add-missing-employee-profile-title"></div>
          <div className="add-missing-employee-profile-count">
            <b>{employeeCount}</b>/{needAddEmployeeList.length} employees added
          </div>
        </div>

        <EmployeeForm
          handleClose={handleCurClose}
          isAddMissingEmployeeModal
          setIsAddComplete={setIsAddComplete}
          isLastAddMissingEmployee={isLastAddMissingEmployee}
          addingEmployeeData={needAddEmployeeList[employeeCount]}
        />
      </div>
    </div>
  );
};

export default AddMissingEmployeeModal;
