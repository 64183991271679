import { createSlice } from '@reduxjs/toolkit';
import { formatMoney } from '../../utils';

const initialState = {
  expensesTotalStats: [],
  expensesArr: [],
  expensesAllData: {},
  totalExpenses: 0
};

export const { actions, reducer } = createSlice({
  name: 'expenses',
  initialState,
  reducers: {
    setExpensesTotalStats: (state, action) => {
      state.expensesTotalStats = action.payload;
    },
    setExpensesAllData: (state, action) => {
      state.expensesAllData = action.payload;
    },
    setExpensesArr: (state, action) => {
      state.expensesArr = action.payload;
    },
    setTotalExpenses: (state, action) => {
      state.totalExpenses = action.payload;
    },

    changeExpensesData: (state, action) => {
      const changeElement = state.expensesArr.map(el => {
        if (el.id === action.payload.id) {
          return {
            ...el,
            tolls_expense: action.payload.tollsExpense,
            tickets_expense: action.payload.ticketsExpense,
            damages_expense: action.payload.damagesExpense,
            repair_maint_expense: action.payload.repairMaintExpense,
            other_expense: action.payload.otherExpense,
          }
        }

        return el;
      });

      const newTotalTolls = changeElement.reduce((acc, el) => {
        if (typeof acc === 'number') {
          return acc + +el.tolls_expense;
        }

        return 0 + +el.tolls_expense;
      }, 0);

      const newTotalTickets = changeElement.reduce((acc, el) => {
        if (typeof acc === 'number') {
          return acc + +el.tickets_expense;
        }

        return 0 + +el.tickets_expense;
      }, 0);

      const newTotalDamages = changeElement.reduce((acc, el) => {
        if (typeof acc === 'number') {
          return acc + +el.damages_expense;
        }

        return 0 + +el.damages_expense;
      }, 0);

      const newTotalRepair = changeElement.reduce((acc, el) => {
        if (typeof acc === 'number') {
          return acc + +el.repair_maint_expense;
        }

        return 0 + +el.repair_maint_expense;
      }, 0);

      const newTotalOther = changeElement.reduce((acc, el) => {
        if (typeof acc === 'number') {
          return acc + +el.other_expense;
        }

        return 0 + +el.other_expense;
      }, 0);


      const newExpensesTotalStats = state.expensesTotalStats.map(el => {
        if (el.name === "Tolls") {
          return { ...el, count: formatMoney(newTotalTolls) }
        }

        if (el.name === "Tickets") {
          return { ...el, count: formatMoney(newTotalTickets) }
        }

        if (el.name === "Damages") {
          return { ...el, count: formatMoney(newTotalDamages) }
        }

        if (el.name === "Repair & Maint") {
          return { ...el, count: formatMoney(newTotalRepair) }
        }

        if (el.name === "Other") {
          return { ...el, count: formatMoney(newTotalOther) }
        }

        return el;
      });

      const calculatedTotalExpenses = changeElement.reduce((acc, cur) => {
        if (acc) {
          return (
            acc +
            +cur.tolls_expense +
            +cur.tickets_expense +
            +cur.damages_expense +
            +cur.repair_maint_expense +
            +cur.other_expense +
            +cur.fixed_expenses
          );
        }

        return (
          0 +
          +cur.tolls_expense +
          +cur.tickets_expense +
          +cur.damages_expense +
          +cur.repair_maint_expense +
          +cur.other_expense +
          +cur.fixed_expenses
        );
      }, 0);

      state.expensesArr = changeElement;
      state.expensesTotalStats = newExpensesTotalStats;
      state.totalExpenses = calculatedTotalExpenses;
    },

    resetPayroll: (state) => {
      state.expensesTotalStats = [];
      state.expensesArr = [];
      state.expensesAllData = {};
      state.totalExpenses = 0;
    }
  }
});

export default reducer;
