import { useNavigate } from "react-router-dom";
import { ReactComponent as FleetWageLogo } from "../../assets/images/fleet-wage-logo.svg";

const LoginLogo = () => {
  const nav = useNavigate();

  const handleNavigate = () => nav("/");

  return (
    <div className="login-logo">
      <button onClick={handleNavigate}>
        <FleetWageLogo />
      </button>
    </div>
  );
};

export default LoginLogo;
