import "../../assets/css/modal.css";

import { Checkbox, Modal, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import axios from "../../axios";
import { actions } from "../../store/auth/auth.reducers";
import SubscriptionModalButtons from "../settings/subscription/SubscriptionModalButtons";
import toastService from "../../services/toastService";

const styles = {
  error: {
    "& div input": {
      borderColor: "red"
    }
  }
};

const schema = yup.object().shape({
  billing_name: yup.string().required("First Name is required!")
});

const SubscriptionAddPaymentMethodModal = ({
  open,
  handleClose,
  setIsNeedRefresh,
  handleSubscribe,
  isFirstCard
}) => {
  const dispatch = useDispatch();

  const [saveLoading, setSaveLoading] = useState(false);
  const [isSetLikeDefault, setIsSetLikeDefault] = useState(false);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      billing_name: ""
    },
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (isFirstCard) {
      setIsSetLikeDefault(true);
    }
  }, []);

  const handleCurClose = () => {
    reset();
    handleClose();
    setIsSetLikeDefault(false);
  };

  const handleIsSetLikeDefaultChange = () => setIsSetLikeDefault(!isSetLikeDefault);

  const onSubmit = (stripe, elements, values) => {
    setSaveLoading(true);

    axios
      .get("/subscription/get-setup-intent")
      .then((response) => {
        if (response.success) {
          stripe
            .confirmCardSetup(response.data.intent.client_secret, {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  name: values.billing_name
                }
              }
            })
            .then((result) => {
              if (result.error) {
                setSaveLoading(false);
                toastService.error('Please check your credit card information and try again')
              } else {
                if (result.setupIntent.status === "succeeded") {
                  axios
                    .post("/subscription/cards/add", {
                      payment_method_id: result.setupIntent.payment_method
                    })
                    .then((response) => {
                      if (response.success) {
                        if (isSetLikeDefault) {
                          axios
                            .post("/subscription/cards/set-default", {
                              payment_method_id: result.setupIntent.payment_method
                            })
                            .then((response) => {
                              if (response.success) {
                                if (isFirstCard) {
                                  axios
                                    .post("/subscription/subscribe", {
                                      paymentMethod: result.setupIntent.payment_method
                                    })
                                    .then((res) => {
                                      if (res.success) {
                                        toastService.success(res.message)
                                        dispatch(actions.setUserIsSubscribed(true));
                                        handleCurClose();
                                        setIsNeedRefresh(true);
                                        setSaveLoading(false);
                                      } else {
                                        toastService.error(res.data.message)
                                      }
                                    })
                                    .catch((err) =>
                                      toastService.error(err.response?.data?.message || err.message)
                                    );
                                } else {
                                  handleCurClose();
                                  setIsNeedRefresh(true);
                                  setSaveLoading(false);
                                  handleSubscribe && handleSubscribe();
                                  toastService.success("Payment method added successfully and set like default method")
                                }
                              }
                            })
                            .catch((err) => {
                              toastService.error(err.response?.data?.message || err.message)
                              setSaveLoading(false);
                            });
                        } else {
                          handleCurClose();
                          setIsNeedRefresh(true);
                          setSaveLoading(false);
                          handleSubscribe && handleSubscribe();
                          toastService.success("Payment method added successfully")
                        }
                      }
                    })
                    .catch((err) => {
                      toastService.error(err.response?.data?.message || err.message)
                      setSaveLoading(false);
                    });
                } else {
                  setSaveLoading(false);
                  toastService.error("Please check your credit card information and try again")
                }
              }
            });
        } else {
          setSaveLoading(false);
        }
      })
      .catch((err) => {
        toastService.error(err.response?.data?.message || err.message)
        setSaveLoading(false);
      });
  };

  return (
    <Modal open={open} onClose={handleCurClose}>
      <div className="confirm-modal subscriptions-modal">
        <div className="confirm-modal-wrapper add-member-modal">
          <Elements stripe={stripePromise}>
            <div className="add-member-modal-header">
              <div className="title-sec">
                <h2>Payment method</h2>
              </div>

              <div className="confirm-modal-close-wrapper" onClick={handleCurClose}>
                <CloseIcon />
              </div>
            </div>

            <div className="stripe-card">
              <h5>Card details:</h5>
              <div className="stripe-card-container">
                <CardElement />
              </div>

              <div className="form-item full-width subscriptions-modal-field">
                <label className={errors.billing_name?.message && "error"}>Card holder name</label>
                <div className="input-box">
                  <TextField
                    sx={errors.billing_name?.message ? styles.error : {}}
                    fullWidth
                    type="text"
                    placeholder="Card holder name"
                    name="billing_name"
                    {...register("billing_name")}
                    error={touchedFields.billing_name && !!errors.billing_name?.message}
                    helperText={errors.billing_name?.message}
                  />
                </div>
              </div>

              <div className="delete-confirm-checkbox-container">
                <Checkbox
                  className="paychex-checkbox"
                  checked={isSetLikeDefault}
                  name="checkbox"
                  disabled={isFirstCard}
                  onChange={handleIsSetLikeDefaultChange}
                />
                <label>Set like default method</label>
              </div>
            </div>

            <SubscriptionModalButtons
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              saveLoading={saveLoading}
              handleClose={handleCurClose}
              isFirstCard={isFirstCard}
            />
          </Elements>
        </div>
      </div>
    </Modal>
  );
};

export default SubscriptionAddPaymentMethodModal;
