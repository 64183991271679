import "../../assets/css/modal.css";
import { Avatar, Modal, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import InputMask from "react-input-mask";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import EditBig from "../../assets/images/settings/edit-big.png";
import axios from "../../axios";
import Loading from "./../common/Loading";
import { getIsDemoMode, getUser } from "../../store/auth/auth.selectors";
import { actions } from "../../store/auth/auth.reducers";
import toastService from "../../services/toastService";

const styles = {
  error: {
    "& div input": {
      borderColor: "red"
    }
  }
};

const schemaAdd = yup.object().shape({
  first_name: yup.string().required("First Name is required!"),
  last_name: yup.string().required("Last Name is required!"),
  email: yup.string().required().email("Invalid format"),
  password: yup
    .string()
    .required("New password is required!")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  password_confirmation: yup
    .string()
    .required("New password is required!")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
});

const schemaEdit = yup.object().shape({
  first_name: yup.string().required("First Name is required!"),
  last_name: yup.string().required("Last Name is required!"),
  password: yup.string().matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, {
    message:
      "Password must contain at least 8 characters, one uppercase, one number and one special case character",
    excludeEmptyString: true
  }),
  password_confirmation: yup
    .string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, {
      message:
        "Password must contain at least 8 characters, one uppercase, one number and one special case character",
      excludeEmptyString: true
    })
});

const AddMemberModal = ({ open, handleClose, setIsAddComplete, editedMember }) => {
  const dispatch = useDispatch();

  const userData = useSelector(getUser);
  const isDemoMode = useSelector(getIsDemoMode);

  const [avatar, setAvatar] = useState();
  const [saveLoading, setSaveLoading] = useState(false);
  const [phone, setPhone] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      first_name: "",
      last_name: "",
      password: "",
      password_confirmation: ""
    },
    resolver: !editedMember ? yupResolver(schemaAdd) : yupResolver(schemaEdit)
  });

  useEffect(() => {
    if (open && editedMember) {
      setValue("first_name", editedMember.first_name, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("last_name", editedMember.last_name, {
        shouldValidate: true,
        shouldDirty: true
      });
      setPhone(editedMember.phone || "");
    }
  }, [open]);

  const handleMainClose = () => {
    handleClose();
    reset();
    avatar && setAvatar();
  };

  const onSubmit = (values) => {
    setSaveLoading(true);

    if (editedMember) {
      if (values.password?.length < 1) {
        delete values.password;
      }
      if (values.password_confirmation?.length < 1) {
        delete values.password_confirmation;
      }
      axios
        .put(`/settings/members/${editedMember.id}`, {
          ...values,
          ...(phone.length > 0 && { phone: phone })
        })
        .then((response) => {
          if (response.success) {
            if (avatar?.file) {
              axios
                .post(`/users/${editedMember.id}/avatar`, avatar.file)
                .then((res) => {
                  if (res.success) {
                    userData.id === editedMember.id && dispatch(actions.updateUserData(res.data));
                    toastService.success(response.message)
                    setIsAddComplete(true);
                    handleMainClose();
                    setSaveLoading(false);
                  }
                })
                .catch((err) => {
                  setSaveLoading(false);
                  if (err.response?.status === 422 || err.code === "ERR_NETWORK") {
                    setAvatar();
                    toastService.error('File is too large, try again with a smaller file')
                  } else {
                    toastService.error(err.response?.data?.message || err.message)
                  }
                });
            } else {
              toastService.success(response.message)
              setIsAddComplete(true);
              handleMainClose();
              setSaveLoading(false);
            }
          }
        })
        .catch((err) => {
          setSaveLoading(false);
          toastService.error(err.response?.data?.message || err.message)
        });
    } else {
      axios
        .post("/settings/members/new", { ...values, ...(phone.length > 0 && { phone: phone }) })
        .then((response) => {
          if (response.success) {
            if (avatar?.file) {
              axios
                .post(`/users/${response.data.member.id}/avatar`, avatar.file)
                .then((res) => {
                  if (res.success) {
                    toastService.success(response.message)
                    setIsAddComplete(true);
                    handleMainClose();
                    setSaveLoading(false);
                  }
                })
                .catch((err) => {
                  setSaveLoading(false);
                  if (err.response?.status === 422 || err.code === "ERR_NETWORK") {
                    setAvatar();
                    toastService.error("File is too large, try again with a smaller file ")
                  } else {
                    toastService.error(err.response?.data?.message || err.message)
                  }
                });
            } else {
              toastService.success('Member successfully added')
              setIsAddComplete(true);
              handleMainClose();
              setSaveLoading(false);
            }
          }
        })
        .catch((err) => {
          setSaveLoading(false);
          toastService.error(err.response?.data?.message || err.message)
        });
    }
  };

  const handleAvatarChange = (e) => {
    const formData = new FormData();
    formData.append("file", e.currentTarget.files[0]);

    setAvatar({ file: formData, url: URL.createObjectURL(e.target.files[0]) });
  };

  return (
    <Modal open={open} onClose={handleMainClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper add-member-modal">
          <div className="add-member-modal-header">
            <div className="title-sec">
              <h2>{editedMember ? "Edit" : "Add"} User</h2>
            </div>

            <div className="confirm-modal-close-wrapper" onClick={handleMainClose}>
              <CloseIcon />
            </div>
          </div>

          <form className="profile-form-sec add-member-modal-form">
            <div className="profile-sec add-member">
              <div className="profile-img profile-info">
                <Avatar
                  variant="circular"
                  className="employees-profile-img"
                  alt="Member avatar"
                  src={avatar?.url || editedMember?.avatar}
                />

                <button className="employees-profile-img-upload profile-info">
                  <img src={EditBig} alt="" />
                  <input
                    type="file"
                    id="file-uploader"
                    accept="image/png, image/jpeg"
                    onChange={handleAvatarChange}
                  />
                </button>
              </div>
            </div>
            <div className="profile-form add-member-form">
              <div className="form-item">
                <label className={errors.first_name?.message && "error"}> First Name *</label>
                <div className="input-box">
                  <TextField
                    sx={errors.first_name?.message ? styles.error : {}}
                    fullWidth
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    required
                    {...register("first_name")}
                    error={touchedFields.first_name && !!errors.first_name?.message}
                    helperText={errors.first_name?.message}
                  />
                </div>
              </div>
              <div className="form-item">
                <label className={errors.last_name?.message && "error"}>Last Name *</label>
                <div className="input-box">
                  <TextField
                    sx={errors.last_name?.message ? styles.error : {}}
                    fullWidth
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    required
                    className={isDemoMode && editedMember ? "blur-text" : ""}
                    {...register("last_name")}
                    error={touchedFields.last_name && !!errors.last_name?.message}
                    helperText={errors.last_name?.message}
                  />
                </div>
              </div>
              {!editedMember && (
                <div className="form-item">
                  <label className={errors.email?.message && "error"}> Email *</label>
                  <div className="input-box">
                    <TextField
                      sx={errors.email?.message ? styles.error : {}}
                      fullWidth
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      required
                      {...register("email")}
                      error={touchedFields.email && !!errors.email?.message}
                      helperText={errors.email?.message}
                    />
                  </div>
                </div>
              )}
              <div className="form-item">
                <label>Phone</label>
                <div className={isDemoMode && editedMember ? "input-box blur-text" : "input-box"}>
                  <InputMask
                    mask="(999) 999-9999"
                    value={phone}
                    disabled={false}
                    onChange={(data) => setPhone(data.target.value)}
                    maskChar=" ">
                    {() => <TextField className="input-mask" />}
                  </InputMask>
                </div>
              </div>
              <div className="form-item">
                <label className={errors.password?.message && "error"}>
                  Password {!editedMember && "*"}
                </label>
                <div className="input-box">
                  <TextField
                    sx={errors.password?.message ? styles.error : {}}
                    fullWidth
                    type="password"
                    placeholder="Enter Password"
                    name="password"
                    inputProps={{
                      autoComplete: "new-password"
                    }}
                    required={!editedMember}
                    {...register("password")}
                    error={touchedFields.password && !!errors.password?.message}
                    helperText={errors.password?.message}
                  />
                </div>
              </div>
              <div className="form-item">
                <label className={errors.password_confirmation?.message && "error"}>
                  Confirm Password {!editedMember && "*"}
                </label>
                <div className="input-box">
                  <TextField
                    sx={errors.password_confirmation?.message ? styles.error : {}}
                    fullWidth
                    type="password"
                    placeholder="Enter Confirm Password"
                    name="password_confirmation"
                    required={!editedMember}
                    {...register("password_confirmation")}
                    error={
                      touchedFields.password_confirmation && !!errors.password_confirmation?.message
                    }
                    helperText={errors.password_confirmation?.message}
                  />
                </div>
              </div>
            </div>
          </form>

          <div className="add-member-form-btn-wrapper">
            <button
              className="btn-style blank-btn confirm-modal-btn company-btn add-popup-cancel"
              onClick={handleMainClose}>
              Cancel
            </button>
            <button
              className="btn-style confirm-modal-btn company company-btn"
              onClick={handleSubmit(onSubmit)}
              disabled={saveLoading}>
              {saveLoading ? <Loading /> : editedMember ? "Save" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddMemberModal;
