import { useEffect, useState } from "react";
import TitleInfo from "../common/TitleInfo";
import SubscriptionPlan from "./subscription/SubscriptionPlan";
import SubscriptionPaymentMethod from "./subscription/SubscriptionPaymentMethod";
import axios from "../../axios";
import SubscriptionBilling from "./subscription/SubscriptionBilling";
import toastService from "../../services/toastService";

const SettingsSubscription = () => {
  const [isNeedRefresh, setIsNeedRefresh] = useState(false);
  const [cards, setCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isNeedRefresh) {
      setIsLoading(true);

      axios
        .get("/subscription/cards")
        .then((response) => {
          if (response.success) {
            setCards(response.data.cards);
          }
        })
        .catch((err) => {
          toastService.error(err.response?.data?.message || err.message)
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsNeedRefresh(false);
    }
  }, [isNeedRefresh]);

  return (
    <div className="profile-companies subscription-tab">
      <TitleInfo title="Subscription" subTitle="Manage your subscription and cards" />

      <SubscriptionPlan setIsNeedRefresh={setIsNeedRefresh} cards={cards} isLoading={isLoading} />

      <SubscriptionPaymentMethod
        isLoading={isLoading}
        cards={cards}
        setIsNeedRefresh={setIsNeedRefresh}
      />

      <SubscriptionBilling />
    </div>
  );
};

export default SettingsSubscription;
