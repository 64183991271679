import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import Loading from "../common/Loading";
import {
  getStatementTotalStats,
  getStatementEmployees,
  getStatementAllData
} from "../../store/payroll-preview/payroll-preview.selectors";
import PayrollTopTotal from "../common/PayrollTopTotal";
import RunPayrollStatementTableRow from "./RunPayrollStatementTableRow";
import EmployeesModal from "../modals/EmployeesModal";
import { ReactComponent as UserAdd } from "../../assets/images/run-payroll-preview/user-add.svg";
import AddExistingEmployeeModal from "../modals/AddExistingEmployeeModal";
import TableSortArrowsBlock from "../common/TableSortArrowsBlock";
import { getStations } from "../../store/data/data.selectors";
import { ReactComponent as Printer } from "../../assets/images/run-payroll-preview/printer.svg";
import { Menu, MenuItem, useMediaQuery } from "@mui/material";
import axios from "../../axios";
import { initiateFileDownloadFromBlob } from "../../helpers/helpers";
import PDF from "../../assets/images/run-payroll-preview/pdf.svg";
import CSV from "../../assets/images/run-payroll-preview/csv.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/settings/arrow-down.svg";
import DefaultPageHeader from '../shared/DefaultPageHeader';
import toastService from "../../services/toastService";

const RunPayrollStatementTable = ({
  loading,
  setIsEditComplete,
  handleDeleteEmployee,
  setSortItem,
  sortItem,
  statementId
}) => {
  const [openAdd, setOpenAdd] = useState(false);
  const [editedUser, setEditedUser] = useState();
  const [isAllOpen, setIsAllOpen] = useState(false);

  const statementTotalStats = useSelector(getStatementTotalStats);
  const employees = useSelector(getStatementEmployees);
  const stationsList = useSelector(getStations);
  const { station_id, id, company_name } = useSelector(getStatementAllData);

  const [isPrintPDFInProgress, setIsPrintPDFInProgress] = useState(false);
  const [isPrintCSVInProgress, setIsPrintCSVInProgress] = useState(false);
  const [isPrintCSVFullInProgress, setIsPrintCSVFullInProgress] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [allItemMoreData, setAllItemMoreData] = useState({});
  const [allMoreDataLoading, setAllMoreDataLoading] = useState(false);

  const isMobile = useMediaQuery("(max-width:990px)");

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const handleEdit = (user) => setEditedUser(user);
  const handleClose = () => setEditedUser();

  const handleAdd = () => setOpenAdd(true);
  const handleAddClose = () => setOpenAdd(false);

  const handleSortChange = (sortItemName, type) =>
    setSortItem({
      name: sortItemName,
      direction: type
    });

  const currentEmployees = useMemo(() => {
    if (!sortItem) {
      return employees;
    }

    const isLastNameAsc = sortItem.name === "employee_name" && sortItem.direction === "asc";
    const isFirstNameDesc = sortItem.name === "employee_name" && sortItem.direction === "desc";

    return [...employees].sort((a, b) => {
      if (isLastNameAsc) {
        return a.employee.last_name.localeCompare(b.employee.last_name);
      } else if (isFirstNameDesc) {
        return a.employee.first_name.localeCompare(b.employee.first_name);
      } else if (sortItem.direction === "asc") {
        return a[sortItem.name] < b[sortItem.name] ? -1 : 1;
      } else {
        return a[sortItem.name] > b[sortItem.name] ? -1 : 1;
      }
    });
  }, [employees, sortItem]);

  const stationMinimumWage = stationsList.find((el) => el.csa === station_id)?.minimum_wage || 7.25;

  const handlePrintPDFReport = (e) => {
    setIsPrintPDFInProgress(true);

    e.preventDefault();
    axios({
      method: "get",
      url: `/statement/${statementId}/download-pdf-report`,
      responseType: "blob"
    })
      .then((response) => {
        initiateFileDownloadFromBlob(response, "statement-report");
      })
      .catch(() => toastService.error('Something went wrong'))
      .finally(() => {
        setIsPrintPDFInProgress(false);
        handleMenuClose();
      });
  };

  const handlePrintCSVReport = (e) => {
    setIsPrintCSVInProgress(true);
    handleMenuClose();

    e.preventDefault();
    axios({
      method: "get",
      url: `/statement/${statementId}/download-csv-report`,
      responseType: "blob"
    })
      .then((response) => {
        initiateFileDownloadFromBlob(response, "statement-report");
      })
      .catch(() => toastService.error('Something went wrong'))
      .finally(() => {
        setIsPrintCSVInProgress(false);
        handleMenuClose();
      });
  };

  const handlePrintCSVFullReport = (e) => {
    setIsPrintCSVFullInProgress(true);
    handleMenuClose();

    e.preventDefault();
    axios({
      method: "get",
      url: `/statement/${statementId}/download-csv-report?detailed=true`,
      responseType: "blob"
    })
      .then((response) => {
        initiateFileDownloadFromBlob(response, "statement-full-report");
      })
      .catch(() => toastService.error('Something went wrong'))
      .finally(() => {
        setIsPrintCSVFullInProgress(false);
        handleMenuClose();
      });
  };

  const handleOpenChange = () => setIsAllOpen(!isAllOpen);

  const fetchItemAllData = () => {
    setAllMoreDataLoading(true);

    axios
      .get(`/statement/${id}/daily-summary`)
      .then((response) => {
        if (response.success) {
          setAllItemMoreData(response.data);
        }
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      )
      .finally(() => setAllMoreDataLoading(false));
  };

  useEffect(() => {
    if (isAllOpen) {
      fetchItemAllData();
    } else if (!isAllOpen && Object.keys(allItemMoreData)) {
      setAllItemMoreData({});
    }
  }, [isAllOpen]);

  const actionButtons = (
    <>
      <button className='btn-style upload-fuel-btn dsw'
              onClick={handleAdd}>
        <UserAdd /> Add Employee
      </button>

      <button
        onClick={handleMenuOpen}
        className='btn-style upload-fuel-btn upload-fuel-no-margin dsw'>
        <Printer />
        {!isMobile && 'Print'}
      </button>
    </>
  );

  return (
    <div className='run-payroll-data statement'>
      {loading ? (
        <Loading />
      ) : (
        <>
          <DefaultPageHeader
            title={company_name}
            actionButtons={actionButtons}
          />

          <div className='run-payroll-data-statement-content'>
            <PayrollTopTotal totalStats={statementTotalStats} />

            <div
              className='payroll-table payroll-data-table statement statement-height'>
              <table>
                <thead>
                <tr>
                  <th>
                    <div className="leaderboard-top-table-header-item">
                        Employee Name{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="employee_name"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        Day{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="working_days"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        Exp Stop{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="perStop"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        Fuel{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="total_fuel"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        Stop{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="total_stops"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        Pkg{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="total_packages"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        Pay{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="total_w2"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        Addition{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="addition"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        Deduction{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="deduction"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        Training{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="training_days"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        PTO{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="pto"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        Hour{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="total_hours"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    <th>
                      <div
                        className="leaderboard-top-table-header-item"
                        style={{ fontWeight: 700 }}>
                        Total{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="totalPay"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        Profit{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="profit"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    {/* <th>
                      <div className="leaderboard-top-table-header-item">
                        Profit{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="profit"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th> */}
                    <th style={{ padding: "12px 10px 12px 15px", minWidth: 100}}>
                      <div style={{ justifyContent: "flex-end", display: "flex" }}>
                        <button
                          onClick={handleOpenChange}
                          className={`companies-btn edit ${isAllOpen ? "up" : ""}`}
                          style={{ background: "transparent" }}>
                          <ArrowDown />
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {currentEmployees.map((el) => (
                    <RunPayrollStatementTableRow
                      key={el.id}
                      itemData={el}
                      handleClick={handleEdit}
                      handleDeleteEmployee={handleDeleteEmployee}
                      stationMinimumWage={stationMinimumWage}
                      isAllOpen={isAllOpen}
                      allMoreDataLoading={allMoreDataLoading}
                      allItemMoreData={allItemMoreData[el.employee_id]}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            className="more-menu report statement">
            <MenuItem onClick={handlePrintPDFReport}>
              <button className="document-more-btn menu-item" disabled={isPrintPDFInProgress}>
                <img src={PDF} alt="" />
              </button>
              Station (pdf)
            </MenuItem>
            <MenuItem onClick={handlePrintCSVReport}>
              <button className="document-more-btn menu-item" disabled={isPrintCSVInProgress}>
                <img src={CSV} alt="" />
              </button>
              Station (csv)
            </MenuItem>
            <MenuItem onClick={handlePrintCSVFullReport}>
              <button className="document-more-btn menu-item" disabled={isPrintCSVFullInProgress}>
                <img src={CSV} alt="" />
              </button>
              <span>Station (csv-full)</span>
            </MenuItem>
          </Menu>

          <EmployeesModal
            open={!!editedUser}
            handleClose={handleClose}
            editedUserId={editedUser?.id}
            setIsEditComplete={setIsEditComplete}
          />

          <AddExistingEmployeeModal
            open={openAdd}
            handleClose={handleAddClose}
            setIsAddComplete={setIsEditComplete}
          />
        </>
      )}
    </div>
  );
};

export default RunPayrollStatementTable;
