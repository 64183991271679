import EmptyState from "../../common/EmptyState";
import Gusto from "../../../assets/images/settings/gusto.svg";
import Paychex from "../../../assets/images/settings/paychex.svg";
import Adp from "../../../assets/images/settings/adp.svg";
import CorporatePayrollServices from "../../../assets/images/settings/corporate_payroll_services.png";
import Netchex from "../../../assets/images/settings/netchex.png";
import SettingsApplicationsCompanyRow from "./ManualApplicationsCompanyRow";
import axios from "../../../axios";
import ConfirmDeleteModal from "../../modals/ConfirmDeleteModal";
import {useState} from "react";
import toastService from "../../../services/toastService";

const icons = {
  Gusto: Gusto,
  Paychex: Paychex,
  "ADP Run": Adp,
  "ADP Run GTSG": Adp,
  "Corporate Payroll Services": CorporatePayrollServices,
  Netchex: Netchex
};

const ManualApplicationsRow = ({ itemData, handleEdit, handleChangeCompany, directIntegrations, templateIntegrations }) => {
  const { name, companies, isActive } = itemData;

  const isGusto = name === "Gusto";
  const isNetchex = name === "Netchex";
  const isCorporatePayrollServices = name === "Corporate Payroll Services";

  const isDefaultCompanyFieldsOnly = isCorporatePayrollServices || isNetchex || isGusto;

  const isCommingSoon = null;

  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);

  const handleOpenDisconnectModal = () => setIsDisconnectModalOpen(true);
  const handleCloseDisconnectModal = () => setIsDisconnectModalOpen(false);

  const handleDisconnect = () => {
    axios
        .post(`/disconnect-payroll-company`)
        .then(() => {
            toastService.success(`${name} disconnected successfully`)
            handleCloseDisconnectModal();
            setTimeout(() => window.location.reload(), 1000);
        })
        .catch((err) =>
          toastService.error(err.response?.data?.message || err.message)
        );
  };

  return (
      <>
          <li
              style={{
                  border: isActive ? "1px solid #7B87F5" : "1px solid #EEF0F2",
                  padding: "24px",
                  minHeight: "100px"
              }}>
              <div className="applications-item">
                  <div className="applications-item-content">
                      <img src={icons[name]} alt=""/>
                      <div className="applications-item-text">
                          <div>{name}</div>
                          <div>Connect {name} to generate CSV file</div>
                      </div>
                  </div>
                  <button
                      disabled={isCommingSoon || (directIntegrations && !isActive)}
                      onClick={
                          isActive
                              ? () => handleOpenDisconnectModal()
                              : !isCommingSoon
                                  ? handleChangeCompany.bind(null, itemData)
                                  : () => {
                                  }
                      }
                      className={`companies-btn ${
                          (isCommingSoon || directIntegrations) && !isActive ? "coming-soon" : isActive ? "disconnect" : "connect-now"
                      }`}>
                      {isCommingSoon ? "Coming soon" : isActive ? "Disconnect" : "Connect now"}
                  </button>
              </div>

              {!isCommingSoon && isActive && !isDefaultCompanyFieldsOnly && (
                  <>
                      {companies?.length < 1 || !companies ? (
                          <div className="companies-informatio-sec">
                              <EmptyState title="No added Company"/>
                          </div>
                      ) : (
                          <div className="settings-applications-company-row">
                              {companies.map((el) => (
                                  <SettingsApplicationsCompanyRow
                                      key={el.id}
                                      companyData={el}
                                      handleEdit={handleEdit}
                                      type={name}
                                  />
                              ))}
                          </div>
                      )}
                  </>
              )}
          </li>

          <ConfirmDeleteModal
              open={isDisconnectModalOpen}
              handleClose={handleCloseDisconnectModal}
              handleConfirm={handleDisconnect}
              text={`Are you sure you want to Disconnect ${name}?`}
              confirmButtonText="Disconnect"
          />
      </>
  );
};

export default ManualApplicationsRow;
