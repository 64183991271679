import "../assets/css/leaderboard.css";
import "../assets/css/overview.css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Checkbox, Chip, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import TitleInfo from "../components/common/TitleInfo";
import TotalStatisticBlocks from "../components/overview/TotalStatisticBlocks";
import TotalRevenueChart from "../components/overview/TotalRevenueChart";
import TopEmployees from "../components/leaderboard/TopEmployees";
import axios from "../axios";
import { getCompanies } from "../store/data/data.selectors";
import { actions } from "../store/data/data.reducers";
import dayjs from "dayjs";
import weekOfYearPlugin from "dayjs/plugin/weekOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import { getThisWeek } from "../helpers/helpers";
import toastService from "../services/toastService";

dayjs.extend(weekOfYearPlugin);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const styles = {
  selectStyle: {
    width: "275px",
    height: "48px",
    borderRadius: "8px",
    "& fieldset": {
      border: "1px solid #EEF0F2"
    },
    "&:hover fieldset": {
      borderColor: "#7B87F5 !important"
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #7B87F5",
      borderColor: "#7B87F5 !important",
      borderWidth: "1px !important",
      boxShadow: "0px 0px 0px 4px #408BFA26"
    }
  },
  selectMenuDisabledItem: {
    "&.Mui-disabled": {
      opacity: 1
    }
  },
  selectMenuItem: {
    "&.Mui-selected": {
      backgroundColor: "transparent !important"
    },
    "& span svg": {
      fill: "#7B87F5"
    }
  },
  select: {
    width: "150px",
    padding: "4px 8px",
    "&::after": {
      display: "none"
    },
    "&::before": {
      display: "none"
    },
    "&.MuiFormControl-root": {
      mb: 0
    },
    "& .MuiInputBase-input.MuiSelect-select": {
      minHeight: "unset",
      padding: 0,
      backgroundColor: "transparent"
    },
    "& .MuiOutlinedInput-input": {
      p: "0 32px 0 8px",
      "@media screen and (max-width: 1024px)": {
        fontSize: 14
      }
    },
    "& .MuiOutlinedInput-root.MuiInputBase-root": {
      border: "none",
      background: "transparent"
    },
    "&.MuiInputBase-root": {
      border: "1px solid #EEF0F2",
      borderRadius: "8px",
      // borderTopRightRadius: "0px",
      // borderBottomRightRadius: "0px",
      backgroundColor: "#fff"
    },
    "& .MuiSelect-root, &.MuiInputBase-root.Mui-focused .MuiSelect-root": {
      backgroundColor: "transparent"
    },
    "& .MuiSelect-icon": {
      color: "colors.statusNeutralDark"
    },
    "& .MuiOutlinedInput-root.MuiInputBase-root.Mui-focused": {
      border: "none",
      background: "transparent"
    }
  }
};

const Overview = () => {
  const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState([0]);
  const [totalBlocks, setTotalBlocks] = useState({});
  const [displayBy, setDisplayBy] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const companyList = useSelector(getCompanies);

  useEffect(() => {
    const curDayToInWeek = dayjs().day();
    const thisWeek = getThisWeek();

    const from =
      displayBy === 0
        ? dayjs("01.01.2020").format("YYYY-MM-DD")
        : displayBy === 3
          ? dayjs().startOf("year").format("YYYY-MM-DD")
          : displayBy === 2
            ? dayjs(thisWeek).subtract(1, "week").format("YYYY-MM-DD")
            : displayBy === 1
              ? thisWeek
              : dayjs()
                  .weekday(6)
                  .subtract(curDayToInWeek < 3 ? displayBy + 1 : displayBy, "week")
                  .format("YYYY-MM-DD");

    const to =
      displayBy === 2
        ? dayjs(thisWeek).subtract(1, "day").format("YYYY-MM-DD")
        : dayjs().weekday(6).subtract(8, "day").format("YYYY-MM-DD");

    setIsLoading(true);

    axios
      .get(
        `/overview?from=${from}&to=${to}&${selectedCompany
          .filter((el) => el !== 0)
          .map((el) => `company_id[]=${el}`)
          .join("&")}`
      )
      .then((response) => {
        setTotalBlocks(response.data);
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      )
      .finally(() => setIsLoading(false));
  }, [selectedCompany, displayBy]);

  useEffect(() => {
    if (companyList.length < 1) {
      axios
        .get("/company-list")
        .then((response) => {
          dispatch(actions.setCompanies(response.data.companies));
        })
        .catch((err) =>
          toastService.error(err.response?.data?.message || err.message)
        );
    }
  }, []);

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;

    if (value.length === 0) {
      setSelectedCompany([0]);
      return;
    }

    setSelectedCompany(
      typeof value === "string" ? value.split(",") : value.filter((el) => el !== 0)
    );
  };

  const renderValue = (selected) => {
    if (selected.includes(0) || selected.length === 0 || selected.length === companyList.length) {
      return "All company";
    }

    const fullElementList = companyList.filter((el) => selected.includes(el.id));

    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {fullElementList.length > 1 ? (
          <>
            <Chip sx={styles.chipStyle} label={fullElementList[0].name} />{" "}
            <div className="three-dots">...</div>
          </>
        ) : (
          fullElementList.map((item) => (
            <Chip sx={styles.chipStyle} key={item.id} label={item.name} />
          ))
        )}
      </Box>
    );
  };

  const companySelect = (
    <Select
      id="demo-multiple-checkbox"
      sx={styles.selectStyle}
      multiple
      value={selectedCompany}
      onChange={handleChange}
      IconComponent={KeyboardArrowDown}
      renderValue={renderValue}>
      <MenuItem value={0} disabled sx={styles.selectMenuDisabledItem}>
        <ListItemText primary="All company" />
      </MenuItem>
      {companyList.map((item) => (
        <MenuItem key={item.id} value={item.id} sx={styles.selectMenuItem}>
          <Checkbox checked={selectedCompany.indexOf(item.id) > -1} />
          <ListItemText primary={item.name} />
        </MenuItem>
      ))}
    </Select>
  );

  const handleRangeChange = (e) => setDisplayBy(e.target.value);

  return (
    <>
      <TitleInfo title="Overview" subTitle="Your weekly summary" jsxItem={companySelect} />

      <div className="overview-total">
        <div className="display-by-select-wrapper overview-total-select">
          <h4>Growth</h4>
          <div className="display-by-select">
            <Typography>Display by:</Typography>

            <Select
              name="displayBy"
              value={displayBy}
              onChange={handleRangeChange}
              variant="standard"
              sx={styles.select}
              IconComponent={KeyboardArrowDown}>
              <MenuItem value={1}>This Week</MenuItem>
              <MenuItem value={2}>Last Week</MenuItem>
              {/* <MenuItem value={2}>Last 2 Weeks</MenuItem>// */}
              <MenuItem value={5}>Last 4 Weeks</MenuItem>
              <MenuItem value={13}>Last 3 Months</MenuItem>
              <MenuItem value={49}>Last 12 Months</MenuItem>
              <MenuItem value={3}>YTD</MenuItem>
              <MenuItem value={0}>All time</MenuItem>
            </Select>
          </div>
        </div>

        <TotalStatisticBlocks totalData={totalBlocks} isLoading={isLoading} displayBy={displayBy}/>
      </div>

      <TotalRevenueChart selectedCompany={selectedCompany} />

      <TopEmployees overview selectedCompany={selectedCompany} />
    </>
  );
};

export default Overview;
