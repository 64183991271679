import { useNavigate } from "react-router-dom";
import { MoreVert } from "@mui/icons-material";
import * as dayjs from "dayjs";
import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DownloadZip from "../../assets/images/run-payroll/download-zip.svg";
import DeleteIcon from "../../assets/images/run-payroll/delete-filled.svg";
import FolderIcon from "../../assets/images/run-payroll/folder.png";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import axios from "../../axios";
import { actions } from "../../store/payroll/payroll.reducers";
import { initiateFileDownloadFromBlob } from "../../helpers/helpers";
import { getUser } from "../../store/auth/auth.selectors";
import toastService from "../../services/toastService";

const RunPayrollTableRow = ({ itemData, index }) => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenDeleteFlexChecksModal, setIsOpenDeleteFlexChecksModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDeleteFlexChecksLoading, setIsDeleteFlexChecksLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const { date, statements_count, created_at, updated_at } = itemData;

  const userData = useSelector(getUser);

  const handlePreviewClick = () => nav(`/run-payroll-preview/${itemData.id}`);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleDownloadZip = () => {
    setIsDownloading(true);
    axios({
      method: "get",
      url: `/payroll/${itemData.id}/download-zip`,
      responseType: "blob"
    })
      .then((response) => {
        setIsDownloading(false);
        initiateFileDownloadFromBlob(
          response,
          `payroll-${dayjs(date).format("MM-DD-YYYY")} - ${dayjs(date)
            .add(6, "d")
            .format("MM-DD-YYYY")}`
        );
      })
      .catch(() =>
        toastService.error("Something went wrong")
      );
  };

  const handleOpenDeleteModal = () => {
    handleClose();
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const handleOpenDeleteFlexChecksModal = () => {
    handleClose();
    setIsOpenDeleteFlexChecksModal(!isOpenDeleteFlexChecksModal);
  };

  const handleCloseDeleteModal = () => {
    handleClose();
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const handleCloseDeleteFlexChecksModal = () => {
    handleClose();
    setIsOpenDeleteFlexChecksModal(!isOpenDeleteFlexChecksModal);
  };

  const handleConfirmDelete = () => {
    setIsDeleteLoading(true);
    axios
      .delete(`/payroll/${itemData.id}`)
      .then((res) => {
        if (res.success) {
          toastService.success('Deleted successfully')
          dispatch(actions.deletePayroll(itemData.id));
          handleCloseDeleteModal();
        } else {
          toastService.error(res.data.message)
        }
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      )
      .finally(() => setIsDeleteLoading(false));
  };

  const handleConfirmDeleteFlexChecks = () => {
    setIsDeleteFlexChecksLoading(true);
    axios
      .post(`/integrations/paychex/delete-checks/${itemData.id}`)
      .then((res) => {
        if (res.success) {
          toastService.success('Delete Flex Checks started')
          handleCloseDeleteFlexChecksModal();
        } else {
          toastService.error(res.data.message)
        }
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      )
      .finally(() => setIsDeleteFlexChecksLoading(false));
  };

  return (
    <tr>
      <td onClick={handlePreviewClick} className="pointer">
        <img src={FolderIcon} alt="" />
        <strong>
          {dayjs(date).format("MM/DD/YYYY")} - {dayjs(date).add(6, "d").format("MM/DD/YYYY")}
        </strong>
        <span>{statements_count} Statements</span>
      </td>
      <td onClick={handlePreviewClick} className="pointer">
        {dayjs(created_at).format("MMM DD, YYYY hh:mm a")}
      </td>
      <td onClick={handlePreviewClick} className="pointer">
        {dayjs(updated_at).format("MMM DD, YYYY hh:mm a")}
      </td>
      <td>
        <button className="document-more-btn" onClick={handleOpen}>
          <MoreVert className="document-more-btn-icon" />
        </button>

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className="more-menu">
          <MenuItem onClick={handleDownloadZip} disabled={isDownloading}>
            <button className="document-more-btn menu-item">
              <img src={DownloadZip} alt="" />
            </button>
            Download Zip
          </MenuItem>
          <MenuItem onClick={handleOpenDeleteModal} disabled={isDeleteLoading}>
            <button className="document-more-btn menu-item">
              <img src={DeleteIcon} alt="" />
            </button>
            Delete
          </MenuItem>
          {index === 0 && (
            <>
              {userData.is_super_admin && userData.directIntegrations && (
                <MenuItem
                  onClick={handleOpenDeleteFlexChecksModal}
                  disabled={isDeleteFlexChecksLoading}>
                  <button className="document-more-btn menu-item">
                    <img src={DeleteIcon} alt="" />
                  </button>
                  Delete Flex Checks
                </MenuItem>
              )}
            </>
          )}
        </Menu>
      </td>
      <ConfirmDeleteModal
        open={isOpenDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleConfirmDelete}
        text="Are you sure delete this Payroll?"
      />
      <ConfirmDeleteModal
        open={isOpenDeleteFlexChecksModal}
        handleClose={handleCloseDeleteFlexChecksModal}
        handleConfirm={handleConfirmDeleteFlexChecks}
        isLoading={isDeleteFlexChecksLoading}
        text="Are you sure delete Flex Checks from this Payroll?"
      />
    </tr>
  );
};

export default RunPayrollTableRow;
