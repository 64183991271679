import { useEffect, useState } from "react";
import TitleInfo from "../../components/common/TitleInfo";
import CouponModal from "../components/modals/CouponModal";
import axios from "../../axios";
import CouponsContainer from "../components/coupon/CouponsContainer";
import toastService from "../../services/toastService";

const Coupons = () => {
  const [isLoading, setLoading] = useState(false);
  const [isAddCoupon, setIsAddCoupon] = useState(false);
  const [isAddCouponLoading, setIsAddCouponLoading] = useState(false);
  const [isUpdateNeed, setIsUpdateNeed] = useState(false);
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    if (isUpdateNeed) {
      setIsUpdateNeed(false);
    } else {
      setLoading(true);

      axios
        .get("/admin/coupons")
        .then((response) => {
          if (response.success) {
            setCoupons(response.data.coupons);
          }
        })
        .catch((err) =>
          toastService.error(err.response?.data?.message || err.message)
        )
        .finally(() => setLoading(false));
    }
  }, [isUpdateNeed]);

  const handleAddCoupon = () => setIsAddCoupon(true);
  const handleAddClose = () => setIsAddCoupon(false);

  const handleAdd = (value, handleCurClose) => {
    setIsAddCouponLoading(true);

    axios
      .post("/admin/coupons", value)
      .then((response) => {
        if (response.success) {
          toastService.success(response.data.message || "Coupon added successfully")

          setIsUpdateNeed(true);
          handleCurClose();
        }
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      )
      .finally(() => setIsAddCouponLoading(false));
  };

  return (
    <>
      <TitleInfo
        title="Coupons"
        subTitle="All the Coupons"
        buttons={[
          {
            name: "+ Add coupon",
            class: "btn-style",
            onClick: handleAddCoupon
          }
        ]}
      />

      <CouponsContainer isLoading={isLoading} coupons={coupons} setIsUpdateNeed={setIsUpdateNeed} />

      <CouponModal
        open={isAddCoupon}
        handleClose={handleAddClose}
        handleConfirm={handleAdd}
        isLoading={isAddCouponLoading}
      />
    </>
  );
};

export default Coupons;
