import { useEffect, useState } from 'react';
import "../assets/css/leaderboard.css";
import TitleInfo from "../components/common/TitleInfo";
import TopEmployees from "../components/leaderboard/TopEmployees";
import axios from "../axios";
import { ReactComponent as Refresh } from "../assets/images/refresh-circle.svg";
import Loading from '../components/common/Loading';
import toastService from "../services/toastService";

const Leaderboard = () => {
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('Today');
  const [loadingLastUpdated, setLoadingLastUpdated] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [filterBy, setFilterBy] = useState(1);

  useEffect(() => {
    fetchLastUpdated();
  }, []);
  const handleRecalculate = () => {
    setLoading(true);

    axios
      .post("/leaderboard/recalculate", { filter: filterBy })
      .then((response) => {
        if (response.success) {
          setIsUpdated(true);
          toastService.success("Successfully Updated")
          fetchLastUpdated()
        }
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      )
      .finally(() => setLoading(false));
  };

  const fetchLastUpdated = () => {
    setLoadingLastUpdated(true)

    axios
    .get("/leaderboard/last-updated")
    .then((response) => {
      if (response.success) {
        setLastUpdated(response.data)
      }
    })
    .catch((err) =>
      toastService.error(err.response?.data?.message || err.message)
    )
    .finally(() => setLoadingLastUpdated(false));
  };

  let lastUpdatedSection = (
    <>
      <div style={{ padding: 10, margin: 0, display: 'flex' }}>
        <span style={{paddingRight: 5}}>Last Updated: </span>
        <span style={{minWidth: 60}}>{loadingLastUpdated
          ? <Loading style={{height: 15, width: 15}}/>
          : lastUpdated}
        </span>
      </div>
    </>
  )

  return (
    <>
      <TitleInfo
        title="Leaderboard"
        subTitle="Leading the pack: The top-ranked workers"
        contentRight={lastUpdatedSection}
        buttons={[
          {
            name: "Update",
            class: "btn-style add-employee-btn leaderboard-btn",
            onClick: handleRecalculate,
            loading: loading,
            icon: <Refresh className="leaderboard-btn-icon" />
          }
        ]}
      />

      <TopEmployees setFilter={setFilterBy} isUpdated={isUpdated} setIsUpdated={setIsUpdated} />
    </>
  );
};

export default Leaderboard;
