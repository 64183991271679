import "../assets/css/run-payroll.css";

import { useEffect, useMemo, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "../axios";
import { actions } from "../store/expenses/expenses.reducers";
import { formatMoney } from "../utils";
import { getBreadCrumbs } from "../store/bread-crumbs/bread-crumbs.selectors";
import { actions as breadCrumbsActions } from "../store/bread-crumbs/bread-crumbs.reducers";
import {
  getExpensesAllData,
  getExpensesArr,
  getExpensesTotalStats,
  getTotalExpenses
} from "../store/expenses/expenses.selectors";
import Loading from "./../components/common/Loading";
import PayrollTopTotal from "./../components/common/PayrollTopTotal";
import TableSortArrowsBlock from "./../components/common/TableSortArrowsBlock";
import ExpensesPreviewTableRow from "../components/expenses/ExpensesPreviewTableRow";
import { ReactComponent as Printer } from "../assets/images/run-payroll-preview/printer.svg";
import {Menu, MenuItem, useMediaQuery} from "@mui/material";
import PDF from "../assets/images/run-payroll-preview/pdf.svg";
import CSV from "../assets/images/run-payroll-preview/csv.svg";
import {
  getPayrollPeriodString,
  initiateFileDownloadFromBlob
} from '../helpers/helpers';
import DefaultPageHeader from '../components/shared/DefaultPageHeader';
import toastService from "../services/toastService";

const ExpensesPreview = () => {
  const dispatch = useDispatch();
  const { payrollId } = useParams();

  const breadCrumbs = useSelector(getBreadCrumbs);
  const expensesAllData = useSelector(getExpensesAllData);
  const expensesTotalStats = useSelector(getExpensesTotalStats);
  const expensesArr = useSelector(getExpensesArr);
  const totalExpenses = useSelector(getTotalExpenses);

  const [loading, setLoading] = useState(false);
  const [sortItem, setSortItem] = useState();

  const [isPrintPDFInProgress, setIsPrintPDFInProgress] = useState(false);
  const [isPrintCSVInProgress, setIsPrintCSVInProgress] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const isMobile = useMediaQuery("(max-width:990px)");

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  useEffect(() => {
    if (expensesAllData.date && (breadCrumbs.length === 0 || breadCrumbs.length > 1)) {
      dispatch(
        breadCrumbsActions.setBreadCrumbs([
          {
            name: "Expenses",
            url: `/expenses`
          },
          {
            name: getPayrollPeriodString(expensesAllData.date)
          }
        ])
      );
    } else if (expensesAllData.date && breadCrumbs.length === 1) {
      dispatch(
        breadCrumbsActions.setBreadCrumbs([
          ...breadCrumbs,
          {
            name: getPayrollPeriodString(expensesAllData.date)
          }
        ])
      );
    }
  }, [expensesAllData]);

  useEffect(() => {
    fetchPayrollData();

    return () => {
      dispatch(actions.resetPayroll());
    };
  }, []);

  const fetchPayrollData = () => {
    setLoading(true);

    axios
      .get(`/payroll/${payrollId}/expenses`)
      .then((response) => {
        const payrollData = response.data.payroll;
        const payrollDataExpenses = response.data.payroll.expense_totals;
        const payrollTotalStats = [
          {
            name: "Tolls",
            count: formatMoney(+payrollDataExpenses.total_tolls)
          },
          {
            name: "Tickets",
            count: formatMoney(+payrollDataExpenses.total_tickets)
          },
          {
            name: "Damages",
            count: formatMoney(+payrollDataExpenses.total_damages)
          },
          {
            name: "Repair & Maint",
            count: formatMoney(payrollDataExpenses.total_repair_maint)
          },
          {
            name: "Other",
            count: formatMoney(payrollDataExpenses.total_other)
          },
          {
            name: "Fixed Expenses",
            count: formatMoney(payrollDataExpenses.total_fixed_expenses)
          }
        ];

        const calculatedTotalExpenses = payrollData.expenses.reduce((acc, cur) => {
          if (acc) {
            return (
              acc +
              +cur.tolls_expense +
              +cur.tickets_expense +
              +cur.damages_expense +
              +cur.repair_maint_expense +
              +cur.other_expense +
              +cur.fixed_expenses
            );
          }

          return (
            0 +
            +cur.tolls_expense +
            +cur.tickets_expense +
            +cur.damages_expense +
            +cur.repair_maint_expense +
            +cur.other_expense +
            +cur.fixed_expenses
          );
        }, 0);

        batch(() => {
          dispatch(actions.setExpensesTotalStats(payrollTotalStats));
          dispatch(actions.setExpensesArr(payrollData.expenses));
          dispatch(actions.setExpensesAllData(payrollData));
          dispatch(actions.setTotalExpenses(calculatedTotalExpenses));
        });
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      )
      .finally(() => setLoading(false));
  };

  const handleSortChange = (sortItemName, type) =>
    setSortItem({
      name: sortItemName,
      direction: type
    });

  const currentStatements = useMemo(() => {
    if (!sortItem) {
      return expensesArr;
    }
    if (sortItem.direction === "asc") {
      return [...expensesArr].sort((a, b) => (a[sortItem.name] < b[sortItem.name] ? -1 : 1));
    }
    return [...expensesArr].sort((a, b) => (a[sortItem.name] > b[sortItem.name] ? -1 : 1));
  }, [expensesArr, sortItem]);

  const handlePrintPDFReport = (e) => {
    setIsPrintPDFInProgress(true);

    e.preventDefault();
    axios({
      method: "get",
      url: `/payroll/${payrollId}/download/expenses/pdf-report`,
      responseType: "blob"
    })
        .then((response) => {
          initiateFileDownloadFromBlob(response, "report-expenses");
        })
        .catch(() => toastService.error('Something went wrong'))
        .finally(() => {
          setIsPrintPDFInProgress(false);
          handleMenuClose();
        });
  };

  const handlePrintCSVReport = (e) => {
    setIsPrintCSVInProgress(true);
    handleMenuClose();

    e.preventDefault();
    axios({
      method: "get",
      url: `/payroll/${payrollId}/download/expenses/csv-report`,
      responseType: "blob"
    })
        .then((response) => {
          initiateFileDownloadFromBlob(response, "report-expenses");
        })
        .catch(() => toastService.error('Something went wrong'))
        .finally(() => {
          setIsPrintCSVInProgress(false);
          handleMenuClose();
        });
  };

  const actionButtons = (
    <>
      <button onClick={handleMenuOpen}
              className='btn-style upload-fuel-btn upload-fuel-no-margin dsw'>
        <Printer />
        {!isMobile && 'Print'}
      </button>
    </>
  )

  return (
    <div className='run-payroll-data'>
      {loading ? (
        <Loading />
      ) : (
        <>
          <DefaultPageHeader
            title={'Expenses For ' + getPayrollPeriodString(expensesAllData?.date)}
            actionButtons={actionButtons}
          />

          <PayrollTopTotal totalStats={expensesTotalStats}/>

          <div className="payroll-table preview payroll-data-table expenses-preview">
            <table>
              <thead>
                <tr>
                  <th>
                    <div className="leaderboard-top-table-header-item">
                      Company{" "}
                      <TableSortArrowsBlock
                        handleSortChange={handleSortChange}
                        itemName="company_name"
                        activeSortedItem={sortItem}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="leaderboard-top-table-header-item">
                      Station{" "}
                      <TableSortArrowsBlock
                        handleSortChange={handleSortChange}
                        itemName="station_name"
                        activeSortedItem={sortItem}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="leaderboard-top-table-header-item">
                      Tolls{" "}
                      <TableSortArrowsBlock
                        handleSortChange={handleSortChange}
                        itemName="station_id"
                        activeSortedItem={sortItem}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="leaderboard-top-table-header-item">
                      Tickets{" "}
                      <TableSortArrowsBlock
                        handleSortChange={handleSortChange}
                        itemName="total_stops"
                        activeSortedItem={sortItem}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="leaderboard-top-table-header-item">
                      Damages{" "}
                      <TableSortArrowsBlock
                        handleSortChange={handleSortChange}
                        itemName="total_fuel"
                        activeSortedItem={sortItem}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="leaderboard-top-table-header-item">
                      Repair & Maint{" "}
                      <TableSortArrowsBlock
                        handleSortChange={handleSortChange}
                        itemName="repair_maint_expense"
                        activeSortedItem={sortItem}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="leaderboard-top-table-header-item">
                      Other{" "}
                      <TableSortArrowsBlock
                        handleSortChange={handleSortChange}
                        itemName="total_w2"
                        activeSortedItem={sortItem}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="leaderboard-top-table-header-item">
                      Fixed Expenses{" "}
                      <TableSortArrowsBlock
                        handleSortChange={handleSortChange}
                        itemName="total_charges"
                        activeSortedItem={sortItem}
                      />
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {currentStatements.map((el) => (
                  <ExpensesPreviewTableRow key={el.id} itemData={el} />
                ))}
              </tbody>
            </table>
          </div>
          <div className="expenses-preview-total" style={{ marginBottom: "5rem" }}>
            <div>Total Expenses</div>
            <div>{formatMoney(totalExpenses)}</div>
          </div>
        </>
      )}

      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose} className="more-menu report">
        <MenuItem onClick={handlePrintPDFReport}>
          <button className="document-more-btn menu-item" disabled={isPrintPDFInProgress}>
            <img src={PDF} alt="" />
          </button>
          PDF
        </MenuItem>
        <MenuItem onClick={handlePrintCSVReport}>
          <button className="document-more-btn menu-item" disabled={isPrintCSVInProgress}>
            <img src={CSV} alt="" />
          </button>
          CSV
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ExpensesPreview;
