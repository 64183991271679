import { useState } from "react";
import { Description } from "@mui/icons-material";
import axios from "../../axios";
import CloseCircle from "../../assets/images/close-circle.png";
import DocumentUploadBig from "../../assets/images/run-payroll/document-upload-big.png";
import toastService from "../../services/toastService";

const PayrollTimeUpload = ({
  open,
  handleClose,
  fetchPayrollData,
  fetchUserData,
  payrollId,
  statementList,
  uploadingTimeOpenType
}) => {
  const [isUploadingTimeFile, setIsUploadingTimeFile] = useState(false);
  const [files, setFiles] = useState({});

  const curHandleClose = () => {
    handleClose();
    setFiles({});
  };

  const handleUploadTimeClick = (e) => {
    e.preventDefault();
    setIsUploadingTimeFile(true);

    const formData = new FormData();

    formData.append("type", uploadingTimeOpenType);
    for (const [key, value] of Object.entries(files)) {
      formData.append(`${key}`, value);
    }

    axios
      .post(`/payroll/${payrollId}/upload-time-card`, formData)
      .then((response) => {
        fetchPayrollData();
        fetchUserData();
        curHandleClose();

        if(response.success){
          toastService.success(response.message)
        }
      })
      .catch((error) => {
        const message = error?.response?.data?.message || "Something went wrong";
        toastService.error(message)
      })
      .finally(() => setIsUploadingTimeFile(false));
  };

  const handleUploadFile = (id, e) => setFiles({ ...files, [id]: e.currentTarget.files[0] });

  const handleDeleteFile = (id) => {
    const filesCopy = { ...files };
    delete filesCopy[id];

    setFiles(filesCopy);
  };

  const isDisabled = isUploadingTimeFile || Object.keys(files).length < 1;

  const getTimeCardText = () => {
    switch (uploadingTimeOpenType) {
      case "beans-route":
        return "Beans Route";
      case "ground-cloud":
        return "GroundCloud.io / Report / Employee Total Hours Report / Export (csv)";
      case "package-route":
        return "Package Route";
    }

    return "";
  };
  return (
    <div className="employees-popup" style={{ display: open ? "flex" : "none" }}>
      <div className="employees-inner upload-gc-inner">
        <div className="popup-top-bar upload-gc-top-bar-container">
          <div className="upload-gc-top-bar">
            <h2>Upload TimeCard</h2>
            <h2>{getTimeCardText()}</h2>
          </div>
          <button className="popup-close edit-popup-cancel" onClick={curHandleClose}>
            <img src={CloseCircle} alt="" />
          </button>
        </div>

        <div className="upload-gc-cards-container">
          {statementList.map((el) => (
            <div key={el.id} className="upload-gc-card">
              <div className="upload-gc-card-title">{el.company_name}</div>
              {files[el.company_id] ? (
                <div className="upload-gc-card-file">
                  <Description fontSize="large" />
                  <div>
                    <div className="upload-gc-card-file-name">File name:</div>
                    {files[el.company_id].name}
                  </div>
                  <button
                    className="popup-close edit-popup-cancel upload-gc-card-file-cancel"
                    onClick={handleDeleteFile.bind(null, el.company_id)}>
                    <img src={CloseCircle} alt="" />
                  </button>
                </div>
              ) : (
                <div className="run-payroll-file-upload-main">
                  <input
                    type="file"
                    id="file-uploader"
                    accept=".csv"
                    onChange={handleUploadFile.bind(null, el.company_id)}
                    multiple
                  />
                  <img src={DocumentUploadBig} alt="" />
                  <h6>
                    Drag & Drop or <strong>Choose file</strong> to upload
                  </h6>
                  <p>CSV</p>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="popup-btns-sec">
          <div className="upload-gc-footer">
            <button className="btn-style blank-btn edit-popup-cancel" onClick={curHandleClose}>
              Cancel
            </button>
            <input
              className="btn-style"
              value="Upload"
              onClick={handleUploadTimeClick}
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollTimeUpload;
