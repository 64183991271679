import { batch, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputMask from "react-input-mask";
import EditBig from "../../assets/images/settings/edit-white.svg";
import { Avatar, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getIsDemoMode, getUser } from "../../store/auth/auth.selectors";
import axios from "../../axios";
import { actions } from "../../store/auth/auth.reducers";
import SettingsMyProfilePassword from "./profile/SettingsMyProfilePassword";
import toastService from "../../services/toastService";

const styles = {
  error: {
    "& div input": {
      borderColor: "red"
    }
  }
};

const schema = yup.object().shape({
  first_name: yup.string().required("First Name is required!"),
  last_name: yup.string().required("Last Name is required!"),
  email: yup.string().email("Invalid format")
});

const SettingsMyProfile = () => {
  const dispatch = useDispatch();
  const userData = useSelector(getUser);
  const isDemoMode = useSelector(getIsDemoMode);
  const [avatar, setAvatar] = useState();

  const [saveLoading, setSaveLoading] = useState(false);
  const [phone, setPhone] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      first_name: "",
      last_name: ""
    },
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    userData.first_name &&
      setValue("first_name", userData.first_name, {
        shouldValidate: true,
        shouldDirty: true
      });
    userData.last_name &&
      setValue("last_name", userData.last_name, {
        shouldValidate: true,
        shouldDirty: true
      });
    userData.email &&
      setValue("email", userData.email, { shouldValidate: true, shouldDirty: true });
    userData.phone && setPhone(userData.phone);
  }, [userData]);

  const onSubmit = (values) => {
    setSaveLoading(true);

    axios
      .put("/auth/user", { ...values, ...(phone.length > 0 && { phone: phone }) })
      .then((response) => {
        if (response.success) {
          if (avatar?.file) {
            axios
              .post(`/users/${userData.id}/avatar`, avatar.file)
              .then((res) => {
                if (res.success) {
                  batch(() => {
                    toastService.success('Profile change successfully saved')
                    dispatch(actions.updateUserData(res.data));
                  });
                }
              })
              .catch((err) => {
                if (err.response?.status === 422 || err.code === "ERR_NETWORK") {
                  setAvatar();
                  toastService.error('File is too large, try again with a smaller file')
                } else {
                  toastService.error(err.response?.data?.message || err.message)
                }
              });
          } else {
            batch(() => {
              toastService.success('Profile change successfully saved');
              dispatch(actions.updateUserData(values));
            });
          }
        }
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      )
      .finally(() => setSaveLoading(false));
  };

  const handleAvatarChange = (e) => {
    const formData = new FormData();
    formData.append("file", e.currentTarget.files[0]);

    setAvatar({ file: formData, url: URL.createObjectURL(e.target.files[0]) });
  };

  return (
    <div className="my-profile-dis settings-tabs profile-info">
      <div className="top-title-bar">
        <div className="title-sec">
          <h2>My profile</h2>
          <h5>Manage your personal information</h5>
        </div>
        <div className="btn-sec">
          <button
            className="btn-style my-profile-save"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={saveLoading}>
            Save changes
          </button>
        </div>
      </div>

      <div className="profile-sec profile-info">
        <div className="profile-img profile-info">
          <Avatar
            variant="circular"
            className="employees-profile-img"
            alt={userData?.first_name}
            src={avatar?.url || userData?.avatar}
          />
          {!saveLoading && (
            <button className="employees-profile-img-upload profile-info">
              <img src={EditBig} alt="" />
              <input
                type="file"
                id="file-uploader"
                accept="image/png, image/jpeg"
                onChange={handleAvatarChange}
              />
            </button>
          )}
        </div>
        <div className="profile-info-text-block">
          <div>
            {userData?.first_name} {userData?.last_name}
          </div>
          <div>{userData?.email}</div>
        </div>
      </div>

      <form className="profile-form-sec">
        <div className="profile-form profile-info">
          <div className="profile-info-form-title">
            <div className="profile-info-form-title-text">Personal Information</div>
          </div>
          <div className="profile-info-form-wrapper">
            <div className="form-item">
              <label className={errors.first_name?.message && "error"}> First Name *</label>
              <div className="input-box">
                <TextField
                  sx={errors.first_name?.message ? styles.error : {}}
                  fullWidth
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  required
                  {...register("first_name")}
                  error={touchedFields.first_name && !!errors.first_name?.message}
                  helperText={errors.first_name?.message}
                />
              </div>
            </div>
            <div className="form-item">
              <label className={errors.last_name?.message && "error"}>Last Name *</label>
              <div className="input-box">
                <TextField
                  sx={errors.last_name?.message ? styles.error : {}}
                  fullWidth
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  required
                  {...register("last_name")}
                  error={touchedFields.last_name && !!errors.last_name?.message}
                  helperText={errors.last_name?.message}
                />
              </div>
            </div>
            <div className="form-item">
              <label className={errors.email?.message && "error"}> Email </label>
              <div className="input-box">
                <TextField
                  sx={errors.email?.message ? styles.error : {}}
                  fullWidth
                  type="email"
                  disabled
                  placeholder="Enter Email"
                  name="email"
                  required
                  {...register("email")}
                  error={touchedFields.email && !!errors.email?.message}
                  helperText={errors.email?.message}
                />
              </div>
            </div>
            <div className="form-item">
              <label>Phone Number</label>
              <div className={isDemoMode ? "blur-text input-box" : "input-box"}>
                <InputMask
                  mask="(999) 999-9999"
                  value={phone}
                  disabled={false}
                  onChange={(data) => setPhone(data.target.value)}
                  maskChar=" ">
                  {() => <TextField className="input-mask" />}
                </InputMask>
              </div>
            </div>
          </div>
        </div>
      </form>
      <SettingsMyProfilePassword />
    </div>
  );
};

export default SettingsMyProfile;
