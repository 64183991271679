import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { Box, Divider, MenuItem, MenuList, Popover, Typography } from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import axios from "../../axios";
import { actions } from "../../store/auth/auth.reducers";
import { actions as crumbsActions } from "../../store/bread-crumbs/bread-crumbs.reducers";
import { actions as dataActions } from "../../store/data/data.reducers";
import { actions as employeeActions } from "../../store/employee/employee.reducers";
import { actions as insightsActions } from "../../store/insights/insights.reducers";
import { actions as leaderboardActions } from "../../store/leaderboard/leaderboard.reducers";
import { actions as organizationsActions } from "../../store/organizations/organizations.reducers";
import { actions as payrollActions } from "../../store/payroll/payroll.reducers";
import { actions as payrollReviewActions } from "../../store/payroll-preview/payroll-preview.reducers";
import { actions as requestsActions } from "../../store/pending-requests/pending-requests.reducers";
import { getUser } from "../../store/auth/auth.selectors";
import toastService from "../../services/toastService";

const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);

  const userData = useSelector(getUser);

  const handleSignOut = useCallback(() => {
    setLoading(true);

    axios
      .post("/auth/logout")
      .then(() => {
        batch(() => {
          dispatch(actions.logOutUser());
          dispatch(crumbsActions.resetBreadCrumbs());
          dispatch(dataActions.resetData());
          dispatch(employeeActions.resetEmployeeData());
          dispatch(insightsActions.resetInsightsData());
          dispatch(leaderboardActions.resetLeaderboardData());
          dispatch(organizationsActions.resetOrganizationsData());
          dispatch(payrollActions.resetPayroll());
          dispatch(payrollReviewActions.resetPayroll());
          dispatch(requestsActions.resetPendingRequestsData());
        });

        localStorage.removeItem("isDemoMode");
        localStorage.removeItem("token");
        nav("/login");
        return;
      })
      .catch((err) => {
        localStorage.removeItem("token");
        localStorage.removeItem("isDemoMode");
        batch(() => {
          dispatch(actions.logOutUser());
          dispatch(crumbsActions.resetBreadCrumbs());
          dispatch(dataActions.resetData());
          dispatch(employeeActions.resetEmployeeData());
          dispatch(insightsActions.resetInsightsData());
          dispatch(leaderboardActions.resetLeaderboardData());
          dispatch(organizationsActions.resetOrganizationsData());
          dispatch(payrollActions.resetPayroll());
          dispatch(payrollReviewActions.resetPayroll());
          dispatch(requestsActions.resetPendingRequestsData());
          toastService.error(err.response?.data?.message || err.message);
        });
        nav("/login");
        return;
      })
      .finally(() => {
        setLoading(false);
        onClose?.();
      });
  }, [onClose, nav]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom"
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}>
      <Box
        sx={{
          py: 1.5,
          px: 2
        }}>
        <Typography variant="overline">Account</Typography>
        <Typography color="text.secondary" variant="body2">
          {userData.first_name} {userData.last_name}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1
          }
        }}>
        <MenuItem onClick={handleSignOut} disabled={loading}>
          Logout
        </MenuItem>
      </MenuList>
    </Popover>
  );
};

export default AccountPopover;
