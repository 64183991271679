import { CircularProgress } from '@mui/material';

const Loading = ({style =null}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
      <CircularProgress style={style}/>
    </div>
  );
}

export default Loading;
