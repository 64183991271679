import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  payrollList: []
};

export const { actions, reducer } = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    setPayrollList: (state, action) => {
      state.payrollList = action.payload;
    },
    deletePayroll: (state, action) => {
      const newPayrollList = state.payrollList.filter((el) => el.id !== +action.payload);

      state.payrollList = newPayrollList;
    },
    resetPayroll: (state) => {
      state.payrollList = [];
    }
  }
});

export default reducer;
