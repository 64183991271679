import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { TextField } from "@mui/material";
import CloseCircle from "../../assets/images/close-circle.png";
import axios from "../../axios";
import toastService from "../../services/toastService";

const names = {
  gusto: "Gusto Payroll",
  paychex: "Paychex",
};

const styles = {
  error: {
    "& div input": {
      borderColor: "red !important"
    }
  }
};

const schema = yup.object().shape({
  client_id: yup.string().required("Client ID is required!"),
  client_secret: yup.string().required("Client Secret is required!")
});

const AppModal = ({ open, handleClose, openKey }) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      client_id: "",
      client_secret: ""
    },
    resolver: yupResolver(schema)
  });

  const onSubmit = (values) => {
    setLoading(true);

    if (openKey === "gusto") {
      axios
          .post("/gusto", values)
          .then((res) => {
            if (res && !res.success) {
              toastService.error(res.message)
              reset();
            }

            axios
                .get("/gusto/get-authorize-url")
                .then((response) => {
                  if (response) {
                    window.open(response, "_self");
                  }
                })
                .catch((err) => {
                  if (err.response.status !== 422) console.log(err);
                })
                .finally(() => setLoading(false));
          })
          .catch((err) => {
            if (err.response.status !== 422) console.log(err);
          });
    }
  };

  return (
    <div
      className="employees-popup integration-app-popup"
      style={{ display: open ? "flex" : "none" }}>
      <div className="employees-inner">
        <div className="popup-top-bar">
          <h2>Provide credential to {names[openKey]}</h2>

          <button onClick={handleClose} className="popup-close">
            <img src={CloseCircle} alt="" />
          </button>
        </div>

        <div className="popup-dis-sec integration-app-form">
          <form className="login-form">
            <div className="input-items">
              <label className={errors.client_id?.message && "error"}>Client ID *</label>
              <TextField
                sx={errors.client_id?.message ? styles.error : {}}
                type="text"
                name="client_id"
                required
                placeholder="Enter Client ID"
                {...register("client_id")}
                error={touchedFields.client_id && !!errors.client_id?.message}
                helperText={errors.client_id?.message}
              />
            </div>
            <div className="input-items">
              <label className={errors.client_secret?.message && "error"}>Client Secret *</label>

              <TextField
                sx={errors.client_secret?.message ? styles.error : {}}
                type="text"
                name="client_secret"
                required
                placeholder="Enter Client Secret"
                {...register("client_secret")}
                error={touchedFields.client_secret && !!errors.client_secret?.message}
                helperText={errors.client_secret?.message}
              />
            </div>
          </form>
        </div>

        <div className="popup-btns-sec">
          <button onClick={handleClose} className="btn-style blank-btn add-popup-cancel">
            Cancel
          </button>

          <input
            type="submit"
            onClick={handleSubmit(onSubmit)}
            className="btn-style add-popup-save"
            disabled={loading}
            value="Request"
          />
        </div>
      </div>
    </div>
  );
};

export default AppModal;
