import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { actions as dataActions } from "../store/data/data.reducers";
import toastService from "../services/toastService";

const echo = new Echo({
  broadcaster: "pusher",
  // eslint-disable-next-line no-undef
  key: process.env.REACT_APP_PUSHER_APP_KEY,
  // eslint-disable-next-line no-undef
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  forceTLS: true
});

export const useWebSocket = (user, isProcessPayrollStarted) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-undef
  Pusher.logToConsole = process.env.NODE_ENV !== 'production';

  useEffect(() => {
    if (echo && isProcessPayrollStarted && Object.keys(user).length) {
      echo.channel(`paychex.organization.${user.organization_id}.process_payroll_failed`).listen("PaychexPayrollProcessedFailed", () => {
        console.log("PaychexPayrollProcessedFailed");
        dispatch(dataActions.setIsProcessPayrollStarted(false));

        toastService.error('Paychex Process Payroll Failed. Please try again later')
      });
      echo.channel(`paychex.organization.${user.organization_id}.process_payroll`).listen("PaychexPayrollProcessedSuccessfully", () => {
        console.log("PaychexPayrollProcessedSuccessfully");
        dispatch(dataActions.setIsProcessPayrollStarted(false));
        toastService.success('Process Payroll Completed')
      });
    }
  }, [echo, user, isProcessPayrollStarted]);

  return { echo };
};

export const useWSPaychex = (user, echo) => {
  const dispatch = useDispatch();

  const handlePaychexProcessedListen = () => {
    if (echo) {
      echo.channel(`paychex.organization.${user.organization_id}.process_payroll_failed`).listen("PaychexPayrollProcessedFailed", () => {
        console.log("PaychexPayrollProcessedFailed");
        dispatch(dataActions.setIsProcessPayrollStarted(false));
        toastService.error('Paychex Process Payroll Failed. Please try again later')
      });
      echo.channel(`paychex.organization.${user.organization_id}.process_payroll`).listen("PaychexPayrollProcessedSuccessfully", () => {
        console.log("PaychexPayrollProcessedSuccessfully");
        dispatch(dataActions.setIsProcessPayrollStarted(false));
        toastService.success('Process Payroll Completed')
      });
    }
  }

  return { handlePaychexProcessedListen };
};
