import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pendingRequests: [],
  data: {}
};

export const { actions, reducer } = createSlice({
  name: 'pendingRequests',
  initialState,
  reducers: {
    setPendingRequests: (state, action) => {
      state.pendingRequests = action.payload;
    },
    setPendingRequestsData: (state, action) => {
      state.data = action.payload;
    },
    resetPendingRequestsData: (state) => {
      state.pendingRequests = [];
      state.data = {};
    }
  }
});

export default reducer;
