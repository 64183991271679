import { MoreVert, Login } from "@mui/icons-material";
import { FormControl, Menu, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from "../../assets/images/run-payroll/delete-filled.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/settings/arrow-down.svg";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import axios from "../../axios";
import relativeTime from "dayjs/plugin/relativeTime";
import EmptyState from "./../common/EmptyState";
import Loading from "./../common/Loading";
import toastService from "../../services/toastService";

const styles = {
  datePicker: {
    width: "100%",
    height: "40px",

    "& div.MuiInputBase-root": {
      paddingRight: "8px",
      borderRadius: 0,
      height: "40px",
      border: "none",
      "&:hover": {
        background: "transparent !important"
      }
    },
    "& div fieldset": {
      border: "none",
      borderRadius: "0px"
    },
    "& div input": {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      fontFamily: "Gilroy",
      padding: 0
    },
    "& div div.MuiInputAdornment-root": {
      marginLeft: 0
    }
  }
};

const OrganizationsTableRaw = ({
  organizationData,
  handleImpersonateOrganizationsChange,
  handleImpersonateOrganizationMemberChange,
  setIsDeleted
}) => {
  dayjs.extend(relativeTime);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [trialDate, setTrialDate] = useState(dayjs(organizationData.trial_date));
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [itemsMoreMembersLoading, setItemsMoreMembersLoading] = useState(false);
  const [itemsMoreMembers, setItemsMoreMembers] = useState([]);

  const open = Boolean(anchorEl);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleOpenDeleteModal = () => {
    handleClose();
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const handleCloseDeleteModal = () => {
    handleClose();
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const handleConfirmDelete = () => {
    setIsDeleteLoading(true);
    axios
      .delete(`/admin/organizations/${organizationData.id}`)
      .then((res) => {
        if (res.success) {
          handleCloseDeleteModal();
          setIsDeleted(true);
          toastService.success('Deleted successfully')
        } else {
          toastService.error(res.data.message)
        }
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      )
      .finally(() => setIsDeleteLoading(false));
  };

  const handleTrialDateChange = (value) =>
    axios
      .post(`/admin/organizations/${organizationData.id}/trial-date`, {
        trial_date: dayjs(value).format("YYYY-MM-DD")
      })
      .then((res) => {
        if (res.success) {
          toastService.success('Trial date changed')
          setTrialDate(value);
        } else {
          toastService.error(res.data.message)
          setTrialDate(dayjs(organizationData.trial_date));
        }
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      );

  const handleOpenChange = () => {
    setIsOpen(!isOpen);
  };

  const fetchItemMoreMembers = () => {
    setItemsMoreMembersLoading(true);

    axios
      .get(`/admin/organizations/${organizationData.id}/users`)
      .then((response) => {
        if (response.success) {
          setItemsMoreMembers(response.data?.users || []);
        }
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      )
      .finally(() => setItemsMoreMembersLoading(false));
  };

  useEffect(() => {
    if (isOpen) {
      fetchItemMoreMembers();
    } else if (!isOpen && itemsMoreMembers) {
      setItemsMoreMembers([]);
    }
  }, [isOpen]);

  return (
    <>
      <tr>
        <td>
          {organizationData.name}
          <b> {organizationData.source === 'package_route'
            ? '[Package Route]'
            : ''}</b>
        </td>
        <td>{organizationData.email}</td>
        <td>{organizationData.subscribed ? 'Yes' : 'No'}</td>
        <td>{dayjs(organizationData.created_at).format('MMM DD, YYYY')}</td>
        <td>{organizationData.last_seen}</td>
        <td>
          {!organizationData.subscribed && (
            <FormControl sx={{ width: '110px', height: '40px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format='YYYY-MM-DD'
                  views={['year', 'month', 'day']}
                  minDate={dayjs().add(1, 'day')}
                  value={trialDate || null}
                  disabled={organizationData.source === 'package_route'}
                  onChange={handleTrialDateChange}
                  sx={styles.datePicker}
                />
              </LocalizationProvider>
            </FormControl>
          )}
        </td>

        <td>{organizationData.companies.length}</td>
        <td>{organizationData.csa_count}</td>
        <td>{organizationData.payroll_count}</td>
        <td>
          <div className='organization-table-row-btns'>
            <div className='statement-button-wrapper'>
              <button className='document-more-btn' onClick={handleOpen}>
                <MoreVert className='document-more-btn-icon' />
              </button>
              <button
                onClick={handleOpenChange}
                className={`companies-btn edit ${isOpen ? 'up' : ''}`}>
                <ArrowDown />
              </button>
            </div>
          </div>

          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}
                className='more-menu'>
            <MenuItem
              onClick={handleImpersonateOrganizationsChange.bind(null,
                organizationData)}
              disabled={!organizationData.email_verified_at}>
              <button className='document-more-btn menu-item'>
                <Login sx={{ color: '#344054' }} />
              </button>
              Login
            </MenuItem>
            <MenuItem onClick={handleOpenDeleteModal}
                      disabled={isDeleteLoading}>
              <button className='document-more-btn menu-item'>
                <img src={DeleteIcon} alt='' />
              </button>
              Delete
            </MenuItem>
          </Menu>
        </td>

        <ConfirmDeleteModal
          open={isOpenDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleConfirm={handleConfirmDelete}
          text='Are you sure delete this Organization?'
        />
      </tr>
      {isOpen && (
        <tr
          className="statement-more-data-container"
          style={{
            borderLeft: !itemsMoreMembers?.length ? "1px solid #EEF0F2" : "none",
            borderRight: !itemsMoreMembers?.length ? "1px solid #EEF0F2" : "none"
          }}>
          <td colSpan="16" className="admin-organization">
            {itemsMoreMembersLoading ? (
              <Loading />
            ) : itemsMoreMembers?.length > 0 ? (
              <div className="statement-more-data-table">
                <table>
                  <thead className="statement-more-data-table-header">
                    <tr>
                      <th>Member Name</th>
                      <th>Email</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemsMoreMembers.map((el) => (
                      <tr key={el.id}>
                        <td>
                          {el.first_name} {el.last_name}
                        </td>
                        <td>{el.email}</td>
                        <td>
                          {organizationData.email_verified_at ? (
                            <button
                              className="document-more-btn"
                              onClick={handleImpersonateOrganizationMemberChange.bind(null, el)}>
                              <Login sx={{ color: "#344054" }} />
                            </button>
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <EmptyState title="No Members" />
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export default OrganizationsTableRaw;
