import DarkTooltip from "./DarkToolTip";
import Loading from "./Loading";

const TitleInfo = ({ title, subTitle, buttons = null, jsxItem = null, id, contentRight = null }) => {
  return (
    <div className="top-title-bar" id={id}>
      <div className="title-sec">
        <h2>{title}</h2>
        <h5>{subTitle}</h5>
      </div>

      <div className="btn-sec">
        {contentRight}
        {buttons &&
          buttons.map((el, index) => (
            <DarkTooltip title={el.tooltipText || ""} key={index} arrow>
              <button
                onClick={el.onClick}
                className={el.class}
                disabled={el.loading || el.disabled}>
                {el.loading ? (
                  <Loading />
                ) : el.icon ? (
                  <>
                    {el.icon} {el.name}
                  </>
                ) : (
                  el.name
                )}
                {el.input && el.input}
              </button>
            </DarkTooltip>
          ))}
        {jsxItem && jsxItem}
      </div>
    </div>
  );
};

export default TitleInfo;
