import "../assets/css/run-payroll.css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import TitleInfo from "./../components/common/TitleInfo";
import RunPayrollDropzone from "../components/run-payroll/RunPayrollDropzone";
import RunPayrollUploadProcess from "../components/run-payroll/RunPayrollUploadProcess";
import RunPayrollContent from "../components/run-payroll/RunPayrollContent";
import axios from "../axios";
import EmptyState from "../components/run-payroll/EmptyState";
import { getPayrollList } from "../store/payroll/payroll.selectors";
import { actions } from "../store/payroll/payroll.reducers";
import Loading from "../components/common/Loading";
import { actions as breadCrumbsActions } from "../store/bread-crumbs/bread-crumbs.reducers";
import { getUser } from "../store/auth/auth.selectors";
import { useTableSortItem } from "../hooks/useTableSortItem";
import MissingEmployeeModal from "../components/modals/MissingEmployeeModal";
import AddMissingEmployeeModal from "../components/modals/AddMissingEmployeeModal";
import toastService from "../services/toastService";

const RunPayroll = () => {
  const dispatch = useDispatch();

  const [filesFormData, setFilesFormData] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayBy, setDisplayBy] = useState(4);
  const { sortItem, setSortItem } = useTableSortItem("run-payroll");
  const [isPaychexIntegrations, setIsPaychexIntegrations] = useState(false);
  const [needAddEmployeeList, setNeedAddEmployeeList] = useState([]);
  const [isNeedAddEmployee, setIsNeedAddEmployee] = useState(false);
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false);

  const userData = useSelector(getUser);

  const payrolls = useSelector(getPayrollList);

  useEffect(() => {
    dispatch(
      breadCrumbsActions.setBreadCrumbs([
        {
          name: "Wage Calculator",
          url: `/run-payroll`
        }
      ])
    );
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => fetchPayrollList(), 500);

    return () => {
      clearTimeout(handler);
    };
  }, [displayBy, sortItem]);

  useEffect(() => {
    if (filesFormData) {
      setIsUploading(true);
      axios
        .post("/payroll/upload", filesFormData)
        .then((res) => {
          if (res) {
            fetchPayrollList();
            toastService.success(res.message)
          }
        })
        .catch((err) => {
          if (
            (err.response?.data?.message.includes("Missing Employees") ||
              err.message.includes("Missing Employees")) &&
            err.response?.data?.data
          ) {
            setIsNeedAddEmployee(true);
            setNeedAddEmployeeList(
              Object.entries(err.response?.data.data).reduce((acc, el) => {
                return [...acc, { ...el[1], fedex_id: el[0] }];
              }, [])
            );
          } else {
            toastService.error(err.response?.data?.message || err.message)
          }
        })
        .finally(() => setIsUploading(false));
    }
  }, [filesFormData]);

  const fetchPayrollList = () => {
    setLoading(true);

    axios
      .get(
        `/payroll?filter=${displayBy}&field=${sortItem?.name || ""}&direction=${
          sortItem?.direction || ""
        }`
      )
      .then((response) => dispatch(actions.setPayrollList(response.data.payrolls)))
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      )
      .finally(() => setLoading(false));
  };

  const handleUploadFile = (e) => {
    const formData = new FormData();
    const files = e.currentTarget.files;

    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i]);
    }

    setFilesFormData(formData);
  };

  const handleUploadCancel = () => setFilesFormData();

  const handleRangeChange = (e) => {
    setDisplayBy(e.target.value);
    localStorage.setItem("runPayrollFilterBy", e.target.value);
    setSortItem();
  };

  const handleSortChange = (sortItemName, type) =>
    setSortItem({
      name: sortItemName,
      direction: type
    });

  const isSubscribedKeyExisting = Object.keys(userData).includes("is_subscribed");
  const isCompanySubscribed = userData.is_subscribed;
  const isPastDue = userData.is_past_due;
  const trialDaysDiff = Math.ceil(dayjs(userData.subscription_ends_at).diff(dayjs(), "hours") / 24);
  const isTrialEnded = userData.is_trial_expired || trialDaysDiff < 1;

  const handleIsNeedAddEmployeeClose = () => {
    setIsNeedAddEmployee(false);
    setNeedAddEmployeeList([]);
  };

  const handleAddEmployeeOpenClose = () => {
    setAddEmployeeOpen(false);
    setNeedAddEmployeeList([]);
  };

  const handleConfirm = () => {
    if (isPaychexIntegrations) {
      window.open("https://myapps.paychex.com", "_blank");
    } else {
      setIsNeedAddEmployee(false);
      setAddEmployeeOpen(true);
    }
  };

  useEffect(() => {
    axios
      .get("/integrations/paychex")
      .then((response) => {
        setIsPaychexIntegrations(
          response?.data?.some((integration) => integration.data.approved) || false
        );
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      );

    const runPayrollFilterBy = localStorage.getItem("runPayrollFilterBy");

    if (runPayrollFilterBy) {
      setDisplayBy(+runPayrollFilterBy);
    }
  }, []);

  return (
    <>
      <TitleInfo
        title="Wage Calculator"
        subTitle="Upload your Charger Statement from MyBizAccount"
      />

      {!isUploading &&
        isSubscribedKeyExisting &&
        !isPastDue &&
        (isCompanySubscribed || (!isCompanySubscribed && !isTrialEnded)) && (
          <RunPayrollDropzone handleUploadFile={handleUploadFile} />
        )}

      {isUploading && <RunPayrollUploadProcess handleUploadCancel={handleUploadCancel} />}

      {loading ? (
        <div className="run-payroll-height">
          <Loading />
        </div>
      ) : payrolls.length ? (
        <RunPayrollContent
          payrolls={payrolls}
          handleRangeChange={handleRangeChange}
          displayBy={displayBy}
          handleSortChange={handleSortChange}
          activeSortedItem={sortItem}
        />
      ) : (
        <EmptyState />
      )}

      <MissingEmployeeModal
        open={isNeedAddEmployee}
        handleClose={handleIsNeedAddEmployeeClose}
        handleConfirm={handleConfirm}
        isPaychexIntegrations={isPaychexIntegrations}
        needAddEmployeeList={needAddEmployeeList}
      />

      <AddMissingEmployeeModal
        open={addEmployeeOpen}
        handleClose={handleAddEmployeeOpenClose}
        needAddEmployeeList={needAddEmployeeList}
      />
    </>
  );
};

export default RunPayroll;
