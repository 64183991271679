import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  employees: [],
  data: {}
};

export const { actions, reducer } = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setEmployee: (state, action) => {
      state.employees = action.payload;
    },
    setEmployeeData: (state, action) => {
      state.data = action.payload;
    },
    resetEmployeeData: (state) => {
      state.employees = [];
      state.data = {};
    }
  }
});

export default reducer;
