import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import FilterBy from "../common/FilterBy";
import TopEmployeesTable from "./TopEmployeesTable";
import Pagination from "./../common/Pagination";
import axios from "../../axios";
import { actions as leaderboardActions } from "../../store/leaderboard/leaderboard.reducers";
import Loading from "../common/Loading";
import {
  getLeaderboardData,
  getLeaderboardList
} from '../../store/leaderboard/leaderboard.selectors';
import EmptyState from "./../common/EmptyState";
import toastService from "../../services/toastService";

const TopEmployees = ({
  isAddComplete,
  overview,
  selectedCompany,
  setFilter,
  isUpdated,
  setIsUpdated
}) => {
  const dispatch = useDispatch();
  const [filterBy, setFilterBy] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortItem, setSortItem] = useState();

  const leaderboardData = useSelector(getLeaderboardData);
  const leaderboardList = useSelector(getLeaderboardList);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    return () => {
      dispatch(leaderboardActions.resetLeaderboardData());
    };
  }, []);

  useEffect(() => {
    if (!isUpdated) {
      fetchEmployeeList();
    }

    if (isUpdated) {
      setIsUpdated(false);
    }
  }, [filterBy, searchQuery, isAddComplete, sortItem, selectedCompany, isUpdated]);

  const fetchEmployeeList = (pageNumber) => {
    setLoading(true);

    if (overview) {
      axios
        .get(
          `/leaderboard/overview?page=${pageNumber || page}&search=${searchQuery}&filter=${filterBy}&field=${
            sortItem?.name || ""
          }&direction=${sortItem?.direction || ""}&per_page=5${
            selectedCompany.length > 0
              ? `&${selectedCompany
                  .filter((el) => el !== 0)
                  .map((el) => `company_id[]=${el}`)
                  .join("&")}`
              : ""
          }`
        )
        .then((response) => {
          batch(() => {
            dispatch(leaderboardActions.setLeaderboardList(response.data.data));
            dispatch(leaderboardActions.setLeaderboardData(response.data));
          });
        })
        .catch((err) =>
          toastService.error(err.response?.data?.message || err.message)
        )
        .finally(() => setLoading(false));
    } else {
      axios
        .get(
          `/leaderboard?page=${pageNumber || page}&search=${searchQuery}&filter=${filterBy}&field=${
            sortItem?.name || ""
          }&direction=${sortItem?.direction || ""}&per_page=20`
        )
        .then((response) => {
          batch(() => {
            dispatch(leaderboardActions.setLeaderboardList(response.data.data));
            dispatch(leaderboardActions.setLeaderboardData(response.data));
          });
        })
        .catch((err) =>
          toastService.error(err.response?.data?.message || err.message)
        )
        .finally(() => setLoading(false));
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
    fetchEmployeeList(value);
  };

  const handleQueryChange = (e) => {
    setSearchQuery(e.target.value || "");
    setPage(1);
  };

  const handleFilterByChange = (value) => {
    setFilter && setFilter(value);
    setFilterBy(value);
    setPage(1);
    setSortItem();
  };

  const handleSortChange = (sortItemName, type) =>
    setSortItem({
      name: sortItemName,
      direction: type
    });

  return (
    <div className="leaderboard-data-list">
      <FilterBy
        title="Top Employees"
        filterBy={filterBy}
        handleFilterByChange={handleFilterByChange}
        searchQuery={searchQuery}
        handleQueryChange={handleQueryChange}
        overview={overview}
      />

      {!leaderboardList.length && !loading ? (
        <EmptyState title="No Employees" />
      ) : loading ? (
        <div className="loader-wrapper">
          <Loading />
        </div>
      ) : (
        <div className="table-scroll-mobile payroll-table leaderboard">
          <TopEmployeesTable
            onlyFirstPage={overview}
            handleSortChange={handleSortChange}
            activeSortedItem={sortItem}
          />
        </div>
      )}

      {leaderboardData.last_page > 1 && !overview && (
        <Pagination page={page} total_pages={leaderboardData.last_page} onChange={handleChange} />
      )}
    </div>
  );
};

export default TopEmployees;
