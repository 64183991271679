import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  organizations: [],
  data: {}
};

export const { actions, reducer } = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setOrganizations: (state, action) => {
      state.organizations = action.payload;
    },
    setOrganizationsData: (state, action) => {
      state.data = action.payload;
    },
    resetOrganizationsData: (state) => {
      state.organizations = [];
      state.data = {};
    }
  }
});

export default reducer;
